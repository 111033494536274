import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiAgent from "../../apiAgent";
import { AppContext } from "../../AppContextProvider";
import { landings } from "../../config";

export const LoggingIn = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  useEffect(() => {
    if (state?.session?.user) {
      navigate(
        (state.session.user?.roles &&
          landings[state.session?.user?.roles[0].name]) ||
          "/requests"
      );
    }
  }, [state?.session?.user, navigate]);
  return <></>;
};
export const LoggingOut = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  useEffect(() => {
    if (!state?.session?.user) {
      navigate("/login");
      apiAgent.session.logout();
    }
  }, [navigate, state?.session?.user]);
  return <></>;
};
