import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Typography, Box, Grid, Button } from "@mui/material";
import apiAgent from "../../apiAgent";
import BackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import { AppContext } from "../../AppContextProvider";
import CardContent from "@mui/material/CardContent";
import { useForm } from "react-hook-form";
import { RHTextField } from "../../components/inputs/RHTextField";
import { RHFAutocomplete } from "../../components/inputs/RHSelect";
import AddIcon from "@mui/icons-material/Add";
import { esES } from "@mui/x-data-grid";
import { ChartComponent } from "./PriceHistory";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { confirmWrapper } from "../../components/confirmDialog";
const supportedLocalesDataGrid = {
  es: esES,
};

const ProductDetail = React.memo(({ product }) => {
  const { t } = useTranslation();
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t(product.productCategoryName)}
        </Typography>
        <Typography variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {product.sku}
        </Typography>
        <Typography variant="body2">
          {product.weight}
          <br />
          {product.description}
        </Typography>
      </CardContent>
    </Card>
  );
});
const AddPrice = React.memo(({ existingPrices, onAdd, franchises }) => {
  const methods = useForm();
  const { handleSubmit, control, reset } = methods;
  const { t } = useTranslation();
  const handleAdd = useCallback(
    (v) => {
      onAdd(v);
      reset();
    },
    [onAdd, reset]
  );
  return (
    <form onSubmit={handleSubmit(handleAdd)} style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          gap: 3,
        }}
      >
        <Box sx={{ width: "40%" }}>
          <RHFAutocomplete
            name="franchise"
            control={control}
            options={franchises || []}
            rules={{ required: t("required") }}
            label={t("franchise")}
            getOptionLabel={(option) => option?.franchiseName || ""}
            disabled={!franchises}
            fullWidth={true}
            isOptionEqualToValue={(option, value) =>
              value === "" || option.franchiseId === value.franchiseId
            }
          />
        </Box>
        <Box sx={{ width: "40%" }}>
          <RHTextField
            control={control}
            label={t("amount")}
            type="number"
            fullWidth
            rules={{ required: "Requerido" }}
            name="amount"
          />
        </Box>
        <Button color="primary" startIcon={<AddIcon />} type="submit">
          {t("addItem")}
        </Button>
      </Box>
    </form>
  );
});
export const ProductPrices = ({ product, onClose }) => {
  const [prices, setPrices] = useState();
  const [franchises, setFranchises] = useState();
  const [historicalPrice, setHistoricalPrice] = useState();
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  useEffect(() => {
    apiAgent.products.prices.getByProduct(product).then((data) => {
      setPrices(data);
    });
  }, [product]);
  const handleAddPrice = useCallback(
    ({ franchise, ...v }) => {
      apiAgent.products.prices
        .add({
          productId: product.productId,
          franchiseId: franchise.franchiseId,
          ...v,
        })
        .then((price) => {
          setPrices((old) => [
            price,
            ...old.filter((c) => c.franchiseId !== price.franchiseId),
          ]);
        });
    },
    [product?.productId]
  );
  const handleDeletePrice = useCallback(
    async (price) => {
      if (!(await confirmWrapper())) return;
      apiAgent.products.prices
        .add({
          productId: product.productId,
          franchiseId: price.franchiseId,
          amount: null,
        })
        .then(() => {
          setPrices((old) =>
            old.filter((c) => price.franchiseId !== c.franchiseId)
          );
        });
    },
    [product?.productId]
  );
  const handleFranchiseClick = useCallback(
    ({ row }) => {
      apiAgent.products.prices
        .getByProduct(product, {
          franchiseId: row.franchiseId,
          historic: true,
        })
        .then((v) => {
          setHistoricalPrice(
            v.map((c) => ({
              time: c.dateFrom,
              value: Number.parseFloat(c.productPriceAmount),
            }))
          );
        });
    },
    [product]
  );

  const columns = useMemo(
    () => [
      {
        field: "franchiseName",
        headerName: t("franchise"),
        width: 180,
        flex: 3,
      },
      {
        field: "productPriceAmount",
        headerName: t("amount"),
        type: "number",
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("actions"),
        width: 100,
        cellClassName: "actions",
        flex: 2,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            icon={<DeleteOutlined />}
            label="Delete"
            onClick={() => handleDeletePrice(row)}
            color="inherit"
          />,
        ],
      },
    ],
    [t, handleDeletePrice]
  );

  useEffect(() => {
    apiAgent.franchises.get({ bypassPagination: true }).then((v) => {
      setFranchises(
        v.data.map((f) => ({
          ...f,
          labelName: `${f.franchiseId} ${f.franchiseName} - ${f.countryName}, ${f.countryStateName}`,
        }))
      );
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          userSelect: "none",
          py: "1em",
          cursor: "pointer",
          gap: 2,
        }}
        onClick={onClose}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <BackIcon />
        </IconButton>
        <Typography sx={{ fontWeight: 800 }} color="primary">
          {t("products")}
        </Typography>
      </Box>
      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid item sm={12} md={4}>
          <ProductDetail product={product} />
          <Box sx={{ mt: 2, boxShadow: "1px 9px 36px -5px #ababab" }}>
            {historicalPrice && <ChartComponent data={historicalPrice} />}
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <AddPrice
            existingPrices={prices}
            franchises={franchises}
            onAdd={handleAddPrice}
          />
          <Box sx={{ height: "300px" }}>
            <DataGrid
              rows={prices || []}
              getRowId={(row) => row.productPriceId}
              columns={columns}
              onRowClick={handleFranchiseClick}
              localeText={
                supportedLocalesDataGrid[language]?.components.MuiDataGrid
                  .defaultProps.localeText
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
