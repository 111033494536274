import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";
import DataGridLocalized from "../../components/DataGrid";
import apiAgent from "../../apiAgent";
import { AppContext } from "../../AppContextProvider";
import { useForm } from "react-hook-form";
import { RHTextField } from "../../components/inputs/RHTextField";
import { useSnackbar } from "notistack";
import { confirmWrapper } from "../../components/confirmDialog";

function EditToolbar(props) {
  const { setProductCategories } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const methods = useForm();
  const { handleSubmit, control, reset, setError } = methods;
  const [errors, setErrors] = useState();
  const { clearCacheKey } = useContext(AppContext);

  const handleClick = useCallback(
    async (newRow) => {
      if (!(await confirmWrapper())) return;
      apiAgent.products.categories.create(newRow).then(
        (newRow) => {
          setProductCategories((oldRows) => [
            { ...newRow, isNew: true },
            ...oldRows,
          ]);
          reset();
          clearCacheKey("productCategories");
        },
        (rej) => {
          const errors = rej?.response?.data?.errors;
          if (errors) {
            setErrors(errors);
            enqueueSnackbar(t("checkValidations"), { variant: "warning" });
          }
        }
      );
    },
    [enqueueSnackbar, reset, setProductCategories, t, clearCacheKey]
  );
  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((name) => {
        setError(name, { message: errors[name].join(" - ") });
      });
    }
  }, [errors, setError]);
  return (
    <GridToolbarContainer sx={{ px: "3em", py: "1em" }}>
      <form onSubmit={handleSubmit(handleClick)} style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",

            alignItems: "flex-start",
            width: "100%",
            gap: 3,
          }}
        >
          <Box sx={{ width: "40%" }}>
            <RHTextField
              control={control}
              label={t("name")}
              fullWidth
              rules={{ required: "Requerido" }}
              name="productCategoryName"
            />
          </Box>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            type="submit"
            sx={{ marginTop: "1em" }}
          >
            {t("addItem")}
          </Button>
        </Box>
      </form>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setProductCategories: PropTypes.func.isRequired,
};

const itemKey = "categoryId";
export default function ProductCategories() {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const {
    getProductCategories,
    state: { productCategories },
    setState,
    clearCacheKey,
  } = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const handleRowEditStart = React.useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);
  const setProductCategories = React.useCallback(
    (value) =>
      setState((old) => ({
        ...old,
        productCategories:
          typeof value === "function" ? value(old.productCategories) : value,
      })),
    [setState]
  );
  const handleRowEditStop = React.useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleEditClick = React.useCallback(
    (id) => () => {
      setRowModesModel((rowModesModel) => ({
        ...rowModesModel,
        [id]: { mode: GridRowModes.Edit },
      }));
      clearCacheKey("productCategories");
    },
    [setRowModesModel, clearCacheKey]
  );

  const handleSaveClick = React.useCallback(
    (id) => () => {
      setRowModesModel((rowModesModel) => ({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View },
      }));
      clearCacheKey("productCategories");
    },
    [clearCacheKey]
  );

  const handleDeleteClick = React.useCallback(
    (id) => async () => {
      if (!(await confirmWrapper())) return;
      apiAgent.products.categories.delete(id).then(
        () => {
          setProductCategories((old) => old.filter((p) => p[itemKey] !== id));
          clearCacheKey("productCategories");
        },
        (rej) => {
          const error = rej.response?.data?.error;
          const messageCode = [error.type, error.code].join("-");
          if (error) {
            enqueueSnackbar([t(messageCode), messageCode].join(". "), {
              variant: "warning",
            });
          }
        }
      );
    },
    [setProductCategories, enqueueSnackbar, t, clearCacheKey]
  );

  const handleCancelClick = React.useCallback(
    (id) => () => {
      setRowModesModel((rowModesModel) => ({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));

      const editedRow = productCategories.find((row) => row[itemKey] === id);
      if (editedRow.isNew) {
        setProductCategories((productCategories) =>
          productCategories.filter((row) => row[itemKey] !== id)
        );
      }
    },
    [productCategories, setProductCategories]
  );

  const processRowUpdate = React.useCallback(
    (newRow) => {
      let promise = apiAgent.products.categories.update;
      newRow.isNew = false;
      return promise(newRow).then((updatedRow) => {
        setProductCategories(
          productCategories.map((row) =>
            row[itemKey] === newRow[itemKey] ? updatedRow : row
          )
        );
        clearCacheKey("productCategories");
        return updatedRow;
      });
    },
    [productCategories, setProductCategories, clearCacheKey]
  );

  const columns = React.useMemo(
    () => [
      {
        field: "productCategoryName",
        headerName: t("name"),
        renderCell: ({ value }) => t(value),
        width: 180,
        editable: true,
        flex: 3,
      },

      {
        field: "actions",
        type: "actions",
        headerName: t("actions"),
        width: 100,
        cellClassName: "actions",
        flex: 1,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [
      handleCancelClick,
      handleDeleteClick,
      handleEditClick,
      handleSaveClick,
      rowModesModel,
      t,
    ]
  );
  React.useEffect(() => {
    getProductCategories({ cache: false });
  }, [getProductCategories]);
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGridLocalized
        rows={productCategories || []}
        columns={columns}
        editMode="row"
        getRowId={(row) => row[itemKey]}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {
            setProductCategories,
            setRowModesModel,
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
