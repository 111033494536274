import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteTwoTone";
import { useTranslation } from "react-i18next";
import apiAgent from "../../apiAgent";
import { confirmWrapper } from "../../components/confirmDialog";
import Save from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";

const KeyValuePairInput = React.memo(({ onSubmit }) => {
  const { t } = useTranslation();
  const [pair, setPair] = useState({ key: "", value: "" });
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!pair.key || !pair.value) return;
      setPair((old) => {
        onSubmit(old);
        return { key: "", value: "" };
      });
    },
    [onSubmit, pair]
  );
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          my: 4,
        }}
      >
        <TextField
          onChange={(e) => setPair((old) => ({ ...old, key: e.target.value }))}
          value={pair.key}
          placeholder={t("name")}
        />
        <TextField
          multiline={true}
          sx={{ flex: "1" }}
          value={pair.value}
          onChange={(e) =>
            setPair((old) => ({ ...old, value: e.target.value }))
          }
        />
      </Box>
      <Button sx={{ width: "100%" }} type="submit" size="small">
        {t("addItem")}
      </Button>
    </form>
  );
});
const DeliveryNoteHeaders = () => {
  const [pairs, setPairs] = useState();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    apiAgent.location.settings.getDeliveryNoteHeaders().then(
      (data) => {
        setPairs(data.content);
      },
      () => {
        setPairs([]);
      }
    );
  }, []);
  const handleSave = useCallback(
    async (v) => {
      if (!(await confirmWrapper())) return;
      apiAgent.location.settings
        .setDeliveryNoteHeaders({
          content: pairs,
        })
        .then(() => {
          enqueueSnackbar(t("updateSuccess"), { variant: "success" });
        });
    },
    [pairs, enqueueSnackbar, t]
  );
  const handleDelete = useCallback(async (i) => {
    if (!(await confirmWrapper())) return;
    else {
      setPairs((old) => {
        const copy = [...old];
        copy.splice(i, 1);
        return copy;
      });
    }
  }, []);
  const handleItemAdd = useCallback(
    (item) => {
      setPairs((old) => [...old, item]);
    },
    [setPairs]
  );

  return (
    <>
      <Container>
        <Card sx={{ p: 3 }}>
          <Typography sx={{ my: 2 }} variant="h5">
            {t("deliveryNote")}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {t("DELIVERY_NOTE_HEADERS_inputKeyValue")}
          </Typography>
          {!pairs && (
            <Box
              sx={{
                minHeight: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {pairs?.map((pair, i) => (
            <React.Fragment key={i}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Typography sx={{ fontWeight: "800" }}>{pair.key}</Typography>
                </Box>
                <Box sx={{ width: "55%", flex: "1 0 0" }}>
                  <Typography>{pair.value}</Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => handleDelete(i)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </React.Fragment>
          ))}
          <KeyValuePairInput onSubmit={handleItemAdd} />
          <Button endIcon={<Save />} type="button" onClick={handleSave}>
            {t("save")}
          </Button>
        </Card>
      </Container>
    </>
  );
};
export default DeliveryNoteHeaders;
