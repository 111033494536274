import React, { useState, useContext, useCallback, useEffect } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import LockIcon from "@mui/icons-material/Lock";
import { Box } from "@mui/material";
import { Avatar } from "@mui/material";
import { AppContext } from "../../AppContextProvider";
import { useNavigate } from "react-router-dom";
import flagUsa from "../../assets/flags/usa.svg";
import flagUr from "../../assets/flags/uruguay.svg";
import imgPortada from "../../assets/freddo-portada.webp";
import { useForm } from "react-hook-form";
import { RHTextField } from "../../components/inputs/RHTextField";
import apiAgent from "../../apiAgent";

const styles = {
  error: {
    fontSize: 14,
    fontWeight: "bold",
    color: "red",
    marginTop: 12,
  },
  leftHalf: {
    backgroundColor: "black",
    height: "calc(100vh - 40px)",
    background: "rgb(0 95 180)",
  },
  imagenPortada: {
    height: "100%",
    objectFit: "cover",
    maxWidth: "60vw",
  },
  rightHalf: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
  },
  rightHalfContainer: {
    flex: 1,
    minWidth: "380px",
    background: "#318cdd",
    display: "flex",
    alignItems: "center",
    overflowY: "auto",
  },
  title: {
    fontSize: "3em",
    color: "white",
    maxWidth: "75% ",
  },
  loginForm: {
    padding: 16,
    width: "60%",
    margin: "auto",
    marginTop: 36,
    border: "solid 1px #c4c4c4",
    borderRadius: 4,
    color: "white",
    background: "white",
  },
  loginButton: {
    marginTop: 16,
    fontSize: 24,
    cursor: "pointer",
  },
  flagsHolder: {
    position: "fixed",
    top: 8,
    right: 12,
  },
  flag: {
    width: 64,
    height: 38,
    cursor: "pointer",
  },
  input: {},
};
const LoginComponent = ({ sx }) => {
  const { language, setLanguage } = useContext(AppContext);
  useEffect(() => {
    apiAgent.session.csrfCookie();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "calc(100vh - 40px)",
        flexDirection: "row-reverse",
      }}
    >
      <RightSection setLanguage={setLanguage} language={language} />
      <LeftSection />
    </div>
  );
};

const LeftSection = React.memo(() => {
  return (
    <Box
      sx={{
        ...styles.leftHalf,
        "@media (max-width: 965px)": {
          display: "none",
        },
      }}
    >
      <img alt="" style={styles.imagenPortada} src={imgPortada} />
    </Box>
  );
});

const RightSection = React.memo(({ language, setLanguage }) => {
  const { t } = useTranslation();

  return (
    <div style={styles.rightHalfContainer}>
      <div style={styles.rightHalf}>
        <div style={styles.title}>{t("systemName")}</div>
        <strong style={{ color: "white" }}>👋 {t("welcome")}</strong>
        <div style={{ fontSize: 18, color: "white", padding: "1em" }}>
          {t("requestInputLoginCredentials")}
          <LoginForm />
        </div>

        <div style={styles.flagsHolder}>
          <img
            alt="uruguay"
            src={flagUr}
            onClick={() => {
              setLanguage("es");
            }}
            style={{ opacity: language === "es" ? 1 : 0.8, ...styles.flag }}
          ></img>
          <img
            alt="usa"
            src={flagUsa}
            onClick={() => {
              setLanguage("en");
            }}
            style={{ opacity: language === "en" ? 1 : 0.8, ...styles.flag }}
          ></img>
        </div>
      </div>
    </div>
  );
});

const LoginForm = React.memo((props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const methods = useForm();
  const { control, handleSubmit } = methods;
  const { setState } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { setLSSession } = useContext(AppContext);

  const handleLogin = useCallback(
    async (values) => {
      setLoading(true);
      apiAgent.session.login(values).then(
        (session) => {
          setState((old) => ({ ...old, session }));
          setLSSession(session);
          setLoading(false);
          navigate("/loggingIn");
        },
        () => {
          setError(true);
          setLoading(false);
        }
      );
    },
    [setState, setLSSession, navigate]
  );

  return (
    <form onSubmit={handleSubmit(handleLogin)} style={styles.loginForm}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <RHTextField
          variant="outlined"
          size="medium"
          name="user"
          control={control}
          label={t("user")}
          rules={{ required: t("required") }}
          InputProps={{
            startAdornment: (
              <Avatar
                style={{ marginRight: 6, maxWidth: "24px", maxHeight: "24px" }}
              />
            ),
            sx: styles.input,
          }}
        />

        <RHTextField
          variant="outlined"
          size="medium"
          control={control}
          name="password"
          label={t("password")}
          type="password"
          rules={{ required: t("required") }}
          sx={styles.input}
          autoComplete="new-password"
          InputProps={{
            startAdornment: <LockIcon style={{ marginRight: 6 }} />,
            sx: styles.input,
          }}
        />

        <Button
          variant="contained"
          disableElevation
          style={styles.loginButton}
          type="submit"
          disabled={loading}
        >
          {t("logIn")}
        </Button>
        {error && <div style={styles.error}>{t("loginError")}</div>}
      </Box>
    </form>
  );
});

export default LoginComponent;
