import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useSnackbar } from "notistack";
//import ProfileModal from "./ProfileModal";
//import { UpdateProfile } from "../../Actions/userActions";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
//import { AppContext } from "../../AppContextProvider";
import AvatarWithUserInitials from "../AvatarWithUserInitials";
import "./Header.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import navbarBackgroundImg from "../../assets/freddo-navbar-background.jpg";
import { AppContext } from "../../AppContextProvider";
import PeopleIcon from "@mui/icons-material/People";
import ViewListIcon from "@mui/icons-material/ViewList";
import IcecreamIcon from "@mui/icons-material/Icecream";
import styled from "@emotion/styled";
import Badge from "@mui/material/Badge";
import { Icon } from "@mui/material";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: "5%",
    right: "5%",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const _pages = [
  {
    label: "users",
    path: "users",
    permission: "create user",
    icon: PeopleIcon,
  },
  {
    label: "requests",
    path: "requests",
    permission: "read request",
    icon: ViewListIcon,
  },
  {
    label: "products",
    path: "products",
    permission: "read product",
    icon: IcecreamIcon,
  },
];
const _avatarMenuOptions = [
  { label: "settings", path: "settings/profile" },
  { label: "logs", path: "logs", permission: "log query" },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { renderIfPermission, handleLogout: appContextHandleLogout } =
    useContext(AppContext);
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [pageSelected, setPageSelected] = useState();
  const navigate = useNavigate();

  const handleOpenNavMenu = useCallback(
    (event) => {
      setAnchorElNav(event.currentTarget);
    },
    [setAnchorElNav]
  );
  const handleOpenUserMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);
  const handleLogout = useCallback(() => {
    appContextHandleLogout().finally(() => {
      enqueueSnackbar(t("logout"), { variant: "info" });
    });
    navigate("/loggingOut");
  }, [enqueueSnackbar, t, navigate, appContextHandleLogout]);
  const pages = useMemo(
    () =>
      _pages.filter((p) =>
        p.permission ? renderIfPermission(p.permission) : true
      ),
    [renderIfPermission]
  );
  const avatarMenuOptions = useMemo(
    () =>
      _avatarMenuOptions.filter((p) =>
        p.permission ? renderIfPermission(p.permission) : true
      ),
    [renderIfPermission]
  );
  useEffect(() => {
    const path = pathname?.substring(1);
    if (path) {
      setPageSelected(path);
    }
  }, [pathname]);
  const pagesHamburguer = useMemo(
    () =>
      pages.map((page) => (
        <MenuItem
          key={page.path}
          onClick={() => {
            navigate("/" + page.path);
            handleCloseNavMenu();
          }}
          sx={{ borderRadius: "0" }}
          selected={page.path === pageSelected}
        >
          <Icon component={page.icon} />
          <Typography textAlign="center">{t(page.label)}</Typography>
        </MenuItem>
      )),
    [pages, handleCloseNavMenu, navigate, pageSelected, t]
  );
  const pagesNavbar = useMemo(
    () =>
      pages.map((page) => (
        <Button
          key={page.label}
          onClick={() => {
            navigate("/" + page.path);
          }}
          color="default"
          sx={{
            display: "flex",
            border: "1px solid lightgray",
            borderRadius: "0",
            alignContent: "center",
            alignItems: "center",
            gap: 1,
            minWidth: "110px",
            background: pageSelected === page.path ? "#28516c" : "initial",
            ":hover": {
              backgroundColor: "#28516c",
            },
          }}
        >
          <Icon component={page.icon} />
          {t(page.label)}
        </Button>
      )),
    [navigate, pageSelected, pages, t]
  );
  const avatarMenuItems = useMemo(
    () =>
      avatarMenuOptions.map((page) => (
        <MenuItem
          key={page.path}
          onClick={() => {
            navigate("/" + (typeof page === "string" ? page : page.path));
            handleCloseUserMenu();
          }}
        >
          {t(page.label)}
        </MenuItem>
      )),
    [avatarMenuOptions, handleCloseUserMenu, navigate, t]
  );
  return (
    <AppBar
      position="sticky"
      sx={{
        height: "115px",
        backgroundImage: "url(" + navbarBackgroundImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        mb: 4,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"
              sx={{ backgroundColor: "#ffffffba", borderRadius: "10px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pagesHamburguer}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {t("systemName")}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "end" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginRight: "1em",
                justifyContent: "center",
              }}
              className="navbar-buttons"
            >
              {pagesNavbar}
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <AvatarWithUserInitials />
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {avatarMenuItems}
              <MenuItem key={"logout"} onClick={handleLogout}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
