import FooterComponent from "./footer";
import HeaderComponent from "./header";

function Layout({ children }) {
  return (
    <>
      <HeaderComponent />
      {children}
      <div style={{ height: "calc(150px - 10vh)" }}></div>
      <FooterComponent />
    </>
  );
}

export default Layout;
