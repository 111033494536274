import DataGridLocalized from "../DataGrid";
import React, {
  useMemo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridToolbarContainer, GridActionsCellItem } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { RHTextField } from "../inputs/RHTextField";
import { useForm, Controller } from "react-hook-form";
import { FormLabel, FormGroup, Grid, Skeleton, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AppContext } from "../../AppContextProvider";
import Autocomplete, {
  createFilterOptions,
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import apiAgent from "../../apiAgent";
import { CustomizedTimeline } from "./timeline";
import { useSnackbar } from "notistack";
import { confirmWrapper } from "../../components/confirmDialog";

const getRequestItemMapKey = (item) =>
  `PRODUCT_ID_${item.productId || item.product.productId}`;
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) =>
    [option?.name, option?.description, option?.sku]
      .filter((option) => !!option)
      .join(" "),
});

const EditProduct = React.forwardRef(
  ({ value, onChange, products, error }, ref) => {
    const handleValueChange = (e, value) => {
      onChange(value);
    };
    const { t } = useTranslation();
    return (
      <Autocomplete
        disablePortal
        onChange={handleValueChange}
        options={products}
        fullWidth
        filterOptions={filterOptions}
        value={value || null}
        getOptionLabel={(option) => option?.name || ""}
        noOptionsText={t("noOptions")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            label=""
            placeholder={t("name,description,orSku")}
          />
        )}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        renderOption={(props, option) => [props, option?.name]}
        renderGroup={(params) => params}
      />
    );
  }
);

const EditToolbar = React.memo((props) => {
  const { setRows, defaultValues } = props;
  const { state, renderIfStrictRole } = useContext(AppContext);
  const products = useMemo(() => state?.products || [], [state?.products]);
  const { control, reset, handleSubmit, setFocus } = useForm();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const { t } = useTranslation();
  const handleAdd = (values) => {
    setRows((oldRows) => {
      const inpKey = getRequestItemMapKey(values);
      if (oldRows[inpKey]) {
        const existingItem = { ...oldRows[inpKey] };
        const newRows = {
          ...oldRows,
          [inpKey]: {
            ...existingItem,
            qty: Number.parseInt(values.qty),
          },
        };
        return newRows;
      }
      return {
        ...oldRows,
        [getRequestItemMapKey(values)]: values,
      };
    });
    reset();
    setFocus("product");
  };

  return (
    <GridToolbarContainer>
      {renderIfStrictRole("franchise") &&
        (!defaultValues || defaultValues.stateName === "STARTED") && (
          <form onSubmit={handleSubmit(handleAdd)} style={{ width: "100%" }}>
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: smUp ? "initial" : "column",
                  width: "100%",
                  alignItems: "flex-start",
                  gap: smUp ? 1 : 0,
                  "& > *": {
                    width: "100%",
                    py: smUp ? "inherit" : "1em",
                  },
                }}
              >
                <Box sx={{ flex: 3, minHeight: "100px" }}>
                  <Controller
                    control={control}
                    name="product"
                    rules={{ required: t("required") }}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <EditProduct
                          ref={ref}
                          products={products}
                          onChange={onChange}
                          value={value}
                          error={error}
                        />
                      </>
                    )}
                  />
                </Box>
                <Box sx={{ flex: 2 }}>
                  <RHTextField
                    type="number"
                    name="qty"
                    control={control}
                    fullWidth
                    inputProps={{ min: 1 }}
                    placeholder={t("quantity")}
                    rules={{
                      required: t("required"),
                      min: {
                        value: 1,
                        message: t("VALIDATION_minimum", { value: 1 }),
                      },
                    }}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Button
                    color="primary"
                    fullWidth
                    startIcon={<AddIcon />}
                    type="submit"
                    variant={smUp ? undefined : "outlined"}
                    sx={{ minHeight: "70px" }}
                  >
                    {smUp && t("addItem")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )}
    </GridToolbarContainer>
  );
});

export default function RequestEditor({
  onCancel,
  onSubmit,
  defaultValues,
  onEstimatedDeliveryDateChange,
}) {
  const [rows, setRows] = React.useState({});
  const [stateHistory, setStateHistory] = useState();
  const { t } = useTranslation();
  const methods = useForm({ defaultValues });
  const { control, watch, handleSubmit } = methods;
  const watchDateFrom = watch("dateFrom");
  const { getProducts, renderIfStrictRole } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const [total, setTotal] = useState();
  const editableOnTable = useMemo(() => {
    return (
      defaultValues &&
      ((defaultValues.stateName === "STARTED" &&
        renderIfStrictRole("franchise", "logistics")) ||
        (defaultValues.stateName === "IN_PROCESS" &&
          renderIfStrictRole("logistics")))
    );
  }, [defaultValues, renderIfStrictRole]);
  const rowsForTable = useMemo(
    () => Object.keys(rows).map((key) => rows[key]),
    [rows]
  );

  useEffect(() => {
    setTotal(
      rowsForTable
        .reduce(
          (prev, current) =>
            prev +
            Number.parseFloat(
              current.product?.productPriceAmount || current.productPriceAmount
            ) *
              current.qty,
          0
        )
        .toFixed(2)
    );
  }, [rowsForTable]);

  const handleDateDeliveryChange = useCallback(
    (e) => {
      const dateEstimatedDelivery = e.target.value;
      apiAgent.request
        .patchDeliveryDate(defaultValues, {
          dateEstimatedDelivery,
        })
        .then((data) => onEstimatedDeliveryDateChange(data));
    },
    [defaultValues, onEstimatedDeliveryDateChange]
  );
  const handleDeleteClick = useCallback(
    ({ row }) =>
      async () => {
        if (!(await confirmWrapper())) return;
        setRows((old) => {
          const newSet = { ...old };
          delete newSet[getRequestItemMapKey(row)];

          return newSet;
        });
      },
    [setRows]
  );

  useEffect(() => {
    if (defaultValues) {
      apiAgent.request.getDetailRows(defaultValues).then((data) => {
        setRows(
          data.reduce(
            (prev, item) => ({
              ...prev,
              [getRequestItemMapKey(item)]: {
                ...item,
                qty: item.requestItemQty,
                name: item.productName,
                productPriceAmount: item.productPriceAmount,
                product: {
                  name: item.productName,
                  priceAmount: item.productPriceAmount,
                },
              },
            }),
            {}
          )
        );
      });
      apiAgent.request.getStateHistory(defaultValues).then((data) => {
        setStateHistory(data);
      });
    }
  }, [defaultValues]);

  const columns = useMemo(
    () => [
      {
        field: "product",
        headerName: t("product"),
        flex: 3,
        renderCell: ({ row }) => row.product?.name || row.productName,
      },
      {
        field: "qty",
        headerName: t("quantity"),
        type: "number",
        flex: 2,
        editable: editableOnTable,
        preProcessEditCellProps: (params) => {
          const hasError = !params.props.value;
          return { ...params.props, error: hasError };
        },
      },
      {
        field: "productPriceAmount",
        headerName: t("price"),
        renderCell: ({ row }) => {
          return row?.product?.productPriceAmount | row.productPriceAmount;
        },
        type: "number",
        flex: 2,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "",
        flex: 1,
        cellClassName: "actions",
        getActions: (row) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(row)}
            color="inherit"
            disabled={
              !!defaultValues
                ? renderIfStrictRole("franchise")
                  ? defaultValues.stateName !== "STARTED"
                  : defaultValues.stateName !== "IN_PROCESS"
                : false
            }
          />,
        ],
      },
    ],
    [t, handleDeleteClick, defaultValues, editableOnTable, renderIfStrictRole]
  );

  const handleSuccessSubmit = useCallback(
    (values) => onSubmit({ ...values, products: rowsForTable }),
    [onSubmit, rowsForTable]
  );

  const handleRowUpdate = useCallback(
    async (newValues) => {
      const { requestItemId, qty: requestItemQty } = newValues;
      if (!(await confirmWrapper())) return;
      try {
        const payload = await apiAgent.request.putQtyDetailRow(requestItemId, {
          requestItemQty,
        });
        enqueueSnackbar(t("requestRowUpdateSuccess"), { variant: "success" });
        return { ...newValues, ...payload };
      } catch {
        return;
      }
    },
    [enqueueSnackbar, t]
  );

  const handleRowUpdateError = useCallback(() => {
    enqueueSnackbar(t("requestRowUpdateError"), { variant: "error" });
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    getProducts({ cache: true }).then(null, (rej) => {
      const message = rej?.response?.data?.message;
      enqueueSnackbar(t("errors-" + message));
    });
  }, [getProducts, enqueueSnackbar, t]);
  return (
    <>
      <>
        <Box sx={{ width: "100%" }}>
          {defaultValues && (
            <>
              <Box>
                <Grid container>
                  <Grid item sm={12} md={5} xs={12}>
                    <hr />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        my: "1em",
                        gap: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5" color="secondary">
                        {t("franchise")}
                      </Typography>
                      <Typography>{defaultValues.franchiseName}</Typography>
                    </Box>
                    <hr />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
        <Grid container>
          {defaultValues && (
            <Grid item sm={12} md={5} xs={12}>
              {stateHistory ? (
                <CustomizedTimeline history={stateHistory} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      px: 3,
                      minWidth: "300px",
                      "& > *": {
                        my: "2em",
                      },
                    }}
                  >
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                </Box>
              )}
            </Grid>
          )}

          <Grid item sm={12} md={defaultValues ? 7 : 12} xs={12}>
            <Box
              sx={{
                height: smUp ? "500px" : "700px",
                pl: smUp ? 3 : undefined,
                width: "95%",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <DataGridLocalized
                rows={rowsForTable}
                columns={columns}
                getRowId={(row) => row.product?.productId || row.requestItemId}
                components={{
                  Toolbar: EditToolbar,
                }}
                componentsProps={{
                  toolbar: { setRows, defaultValues },
                }}
                processRowUpdate={handleRowUpdate}
                onProcessRowUpdateError={handleRowUpdateError}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                my: "1em",
                mx: "2px",
                width: "100%",
              }}
            >
              Total ${total}
            </Box>
          </Grid>
        </Grid>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box sx={{ p: 1 }}>
            <Typography color="primary" variant="h5" sx={{ mt: 3 }}>
              {t("deliveryDate")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "& > *": {
                  flex: "1 0 auto",
                },
                gap: "3em",
              }}
            >
              <Box>
                <Typography color="secondary" variant="h6" sx={{ mt: 3 }}>
                  {t("franchise")}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: smUp ? "initial" : "column",
                    gap: 2,
                    width: "100%",
                    mt: "1em",
                    "& > *": {
                      width: "100%",
                    },
                  }}
                >
                  <FormGroup>
                    <FormLabel>{t("dateFrom")}</FormLabel>
                    <RHTextField
                      type="date"
                      name="dateFrom"
                      control={control}
                      disabled={
                        !(
                          renderIfStrictRole("franchise") &&
                          (!defaultValues ||
                            defaultValues.stateName === "STARTED")
                        )
                      }
                      rules={{ required: t("required") }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>{t("dateTo")}</FormLabel>
                    <RHTextField
                      type="date"
                      name="dateTo"
                      control={control}
                      disabled={
                        !(
                          renderIfStrictRole("franchise") &&
                          (!defaultValues ||
                            defaultValues.stateName === "STARTED")
                        )
                      }
                      inputProps={{ min: watchDateFrom }}
                      rules={{ required: t("required") }}
                    />
                  </FormGroup>
                </Box>
              </Box>
              <Box>
                {defaultValues?.stateName !== "STARTED" && (
                  <>
                    <Typography color="secondary" variant="h6" sx={{ mt: 3 }}>
                      {t("logistics")}
                    </Typography>
                    <FormGroup sx={{ mt: "1em" }}>
                      <FormLabel>{t("dateEstimatedDelivery")}</FormLabel>
                      <RHTextField
                        type="date"
                        extOnChange={handleDateDeliveryChange}
                        disabled={
                          !(
                            renderIfStrictRole("logistics") &&
                            defaultValues.stateName === "IN_PROCESS"
                          )
                        }
                        name="dateEstimatedDelivery"
                        control={control}
                      />
                    </FormGroup>
                  </>
                )}
                {defaultValues && defaultValues.stateName === "SUCCESSFULL" && (
                  <>
                    <Typography color="secondary" variant="h6" sx={{ mt: 3 }}>
                      {t("dateEstimatedDelivery")}
                    </Typography>
                    <Typography>
                      {defaultValues.dateEstimatedDelivery}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
            my: "1em",
          }}
        >
          <RHTextField
            multiline
            minRows={2}
            maxRows={4}
            label={t("comments")}
            name="comments"
            disabled={
              !(
                renderIfStrictRole("franchise") &&
                (defaultValues ? defaultValues?.stateName === "STARTED" : true)
              )
            }
            control={control}
            fullWidth
          />
        </Box>
      </>

      <Box
        className="buttons"
        sx={{
          display: "flex",
          gap: 2,
          py: 5,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          type="button"
          color="secondary"
          onClick={onCancel}
          variant="contained"
          sx={{ width: "48%" }}
        >
          {t(defaultValues ? "close" : "cancel")}
        </Button>
        {(!defaultValues ||
          (renderIfStrictRole("franchise") &&
            defaultValues.stateName === "STARTED")) && (
          <Button
            type="submit"
            color="primary"
            disabled={rowsForTable.length === 0}
            onClick={handleSubmit(handleSuccessSubmit)}
            variant="contained"
            sx={{ width: "48%" }}
          >
            {defaultValues ? t("save") : t("create")}
          </Button>
        )}
      </Box>
    </>
  );
}
