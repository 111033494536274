import React, { useId, useCallback } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const RHSelect = ({
  name,
  control,
  options,
  rules,
  label,
  fullWidth,
  valueKey = "value",
  labelKey = "label",
  renderValue,
  ...rest
}) => {
  const uid = useId();
  const generatedSelectOptions = useCallback(() => {
    return (
      options?.map((option) => (
        <MenuItem key={option[valueKey]} value={option[valueKey]}>
          {renderValue ? renderValue(option[labelKey]) : option[labelKey]}
        </MenuItem>
      )) || []
    );
  }, [options, valueKey, labelKey, renderValue]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth sx={{ minHeight: "100px" }}>
          {label && <InputLabel id={uid}>{label}</InputLabel>}
          <Select
            fullWidth={fullWidth}
            label={label}
            labelId={uid}
            onChange={onChange}
            value={options ? value || "" : ""}
            error={!!error}
            {...rest}
          >
            {generatedSelectOptions()}
          </Select>
          {error?.message && (
            <FormHelperText error={true}>{error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
export const RHFAutocomplete = ({
  name,
  control,
  options,
  rules,
  label,
  fullWidth,
  serverMode = false,
  onChangeInputValue,
  filterOptions,
  groupBy,
  getOptionLabel,
  defaultValue,
  inputProps,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue || null}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          disablePortal
          options={options}
          value={value}
          filter={serverMode ? (x) => x : undefined}
          groupBy={groupBy}
          filterOptions={filterOptions}
          onInputChange={onChangeInputValue}
          getOptionLabel={getOptionLabel}
          onChange={(_, v) => onChange(v)}
          noOptionsText={t("noOptions")}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                ...inputProps,
              }}
              error={!!error?.message}
              helperText={error?.message}
              label={label}
            />
          )}
          {...rest}
        />
      )}
    />
  );
};
