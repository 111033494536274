import React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import Layout from "./components/layout";
import FooterComponent from "./components/layout/footer";
import LoginComponent from "./pages/login";
import Users from "./pages/adminHome";
import Products from "./pages/products";
import Requests from "./pages/requests";
import Settings from "./pages/settings";
import Logs from "./pages/logs";
import Profile from "./pages/settings/Profile";
import ProductCategories from "./pages/settings/ProductCategories";
import Franchises from "./pages/settings/Franchises";
import GuardedRoute from "./components/GuardedRoute";
import { LoggingIn, LoggingOut } from "./pages/login/Suspense";
import DeliveryNoteHeaders from "./pages/settings/DeliveryNoteHeaders";

const RoutesApp = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route
          path="users"
          element={
            <GuardedRoute permissions={["read user"]}>
              <Users />
            </GuardedRoute>
          }
        />

        <Route
          path="settings"
          element={
            <GuardedRoute>
              <Settings />
            </GuardedRoute>
          }
        >
          <Route path="profile" element={<Profile />} />
          <Route
            path="productCategories"
            element={
              <GuardedRoute permissions={["read product category"]}>
                <ProductCategories />
              </GuardedRoute>
            }
          />
          <Route
            path="franchises"
            element={
              <GuardedRoute permissions={["read franchise"]}>
                <Franchises />
              </GuardedRoute>
            }
          />
          <Route
            path="countryConfiguration"
            element={
              <GuardedRoute permissions={["read franchise"]}>
                <DeliveryNoteHeaders />
              </GuardedRoute>
            }
          />
        </Route>
        <Route
          path="products"
          element={
            <GuardedRoute permissions={["read product"]}>
              <Products />
            </GuardedRoute>
          }
        />
        <Route
          path="requests"
          element={
            <GuardedRoute permissions={["read request"]}>
              <Requests />
            </GuardedRoute>
          }
        />
        <Route
          path="logs"
          element={
            <GuardedRoute permissions={["log query"]}>
              <Logs />
            </GuardedRoute>
          }
        />
        <Route path="/loggingIn" element={<LoggingIn />} />
        <Route path="/loggingOut" element={<LoggingOut />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Route>
      <Route
        path="login"
        element={
          <>
            <LoginComponent />
            <FooterComponent />
          </>
        }
      />
    </Routes>
  );
};

export default RoutesApp;
