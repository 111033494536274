import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CancelIcon from "@mui/icons-material/Cancel";
import BeenhereIcon from "@mui/icons-material/Beenhere";

export const basicUserFields = [
  "username",
  "firstName",
  "lastName",
  "password",
  "email",
];

export const roles = [
  {
    id: 1,
    name: "franchise",
    requiredFields: [
      ...basicUserFields,
      "franchise",
      "address",
      "postalCode",
      "city",
      "state",
      "country",
      "telephone",
    ],
  },
  {
    id: 2,
    name: "logistics",
    requiredFields: [...basicUserFields, "country", "state"],
  },
  {
    id: 3,
    name: "admin",
    only: ["super-admin"],
    requiredFields: [...basicUserFields, "country", "state"],
  },
];
export const landings = {
  franchise: "/requests",
  admin: "/users",
  logistics: "/requests",
};

export const statesIcons = {
  STARTED: { icon: <KeyboardArrowRightIcon />, color: "primary" },
  IN_PROCESS: { icon: <RotateLeftIcon />, color: "secondary" },
  CANCELED: { icon: <CancelIcon />, color: "error" },
  DECLINED: { icon: <DoNotDisturbOnIcon />, color: "error" },
  SUCCESSFUL: { icon: <BeenhereIcon />, color: "success" },
};
export const statesTransitions = {
  STARTED: {
    logistics: ["IN_PROCESS", "DECLINED"],
    franchise: ["CANCELED"],
  },
  CANCELED: {
    franchise: ["STARTED"],
  },
  DECLINED: {
    logistics: ["IN_PROCESS"],
  },
  IN_PROCESS: {
    logistics: ["DECLINED", "SUCCESSFUL"],
  },
};
