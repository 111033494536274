import { createContext, useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import apiAgent from "./apiAgent";
import { roles } from "./config";
import { ClientStorage } from "./ClientStorageHelper";
export const AppContext = createContext();

let _cache = {};

const memoize = async (key, promise, options) => {
  const defaultOptions = { cache: true };
  options = { ...defaultOptions, ...options };
  if (
    (!_cache[key] && !(_cache[key] = ClientStorage.get(key))) ||
    !options.cache
  ) {
    _cache[key] = await promise();
    ClientStorage.set(key, _cache[key]);
  }
  return _cache[key];
};
const clearCacheKey = (key) => {
  delete _cache[key];
  ClientStorage.remove(key);
};
const clearAllCache = () => {
  _cache = {};
  ClientStorage.clear();
};

let _xlsx = null;
const XLSX = async () => {
  if (!_xlsx) {
    _xlsx = await import("xlsx");
  }
  return _xlsx;
};
const getImgsHost = () => process.env.REACT_APP_IMAGES_HOST_URL || "";

const AppContextProvider = ({ children }) => {
  const { isAdmin, isError, isFranchise } = {
    isAdmin: true,
    isError: true,
    isFranchise: false,
  };
  const [language, setLanguage] = useLocalStorage("i18locale", "es");
  const [LSSESSION, setLSSession] = useLocalStorage("session");

  const [state, setState] = useState({
    roles,
    session: LSSESSION,
  });
  const getRoles = useCallback(async () => {
    if (state?.roles) {
      return state.roles;
    }
  }, [state.roles]);
  const getStates = useCallback(async (country) => {
    const statesImport = () => apiAgent.location.states.getByCountry(country);
    if (country?.states) {
      return country.states;
    } else {
      const states = await memoize(
        "CTRID_" + country.countryId + "STATES",
        statesImport
      );
      setState((old) => ({
        ...old,
        countries: old.countries.map((countryCursor) =>
          countryCursor.countryId === country.countryId
            ? { ...countryCursor, states }
            : countryCursor
        ),
      }));
      return states;
    }
  }, []);
  const { i18n } = useTranslation();
  const handleLanguageChange = useCallback(
    (locale) => {
      i18n.changeLanguage(locale);
    },
    [i18n]
  );
  const getCountries = useCallback(async () => {
    return memoize("countries", apiAgent.location.countries.all).then(
      (countries) => {
        setState((old) => ({ ...old, countries }));
        return countries;
      }
    );
  }, []);

  const getProductCategories = useCallback(async (options) => {
    const productCategories = await memoize(
      "productCategories",
      apiAgent.products.categories.getAll,
      options
    );
    setState((old) => ({ ...old, productCategories }));
    return productCategories;
  }, []);
  const getProducts = useCallback(async ({ cache = false } = {}) => {
    const products = await memoize("products", apiAgent.products.getAll, {
      cache,
    });
    setState((old) => ({
      ...old,
      products,
    }));
    return products;
  }, []);
  const getLogTypes = useCallback(async ({ cache = false } = {}) => {
    const logTypes = await memoize("logTypes", apiAgent.logs.getTypes, {
      cache,
    });
    setState((old) => ({
      ...old,
      logTypes,
    }));
    return logTypes;
  }, []);

  const renderIfStrictRole = useCallback(
    (...roles) =>
      state?.session?.user?.roles?.some((r) => roles.indexOf(r.name) >= 0),
    [state?.session?.user?.roles]
  );
  const renderIfRole = useCallback(
    (...roles) =>
      state?.session?.user?.roles?.some(
        (r) => roles.indexOf(r.name) >= 0 || r.name === "super-admin"
      ),
    [state?.session?.user?.roles]
  );
  const renderIfPermission = useCallback(
    (...permissions) =>
      renderIfRole("admin") ||
      state?.session?.user?.permissions?.some(
        (r) => permissions.indexOf(r) >= 0
      ),
    [state.session?.user?.permissions, renderIfRole]
  );
  const handleLogout = useCallback(async () => {
    setLSSession(undefined);
    setState({
      roles,
      session: undefined,
    });
    clearAllCache();
  }, [setLSSession, setState]);
  useEffect(() => {
    handleLanguageChange(language);
  }, [handleLanguageChange, language]);

  useEffect(() => {
    apiAgent.session.setHandleForbidden(handleLogout);
  }, [handleLogout]);
  return (
    <>
      <AppContext.Provider
        value={{
          XLSX,
          isAdmin,
          isError,
          isFranchise,
          language,
          setLanguage,
          state,
          setState,
          getRoles,
          getStates,
          getCountries,
          getProductCategories,
          getProducts,
          getLogTypes,
          clearCacheKey,
          renderIfPermission,
          renderIfRole,
          renderIfStrictRole,
          setLSSession,
          LSSESSION,
          handleLogout,
          getImgsHost,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  );
};
export default AppContextProvider;
