import { useCallback } from "react";
import { Box, FormControl, Select, MenuItem, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContextProvider";
import { useTranslation } from "react-i18next";
import logoFooter from "../../assets/freddo-logo-footer.webp";
import apiAgent from "../../apiAgent";

const FooterComponent = (props) => {
  const version = require("../../../package.json").version;
  const { setLanguage, language } = useContext(AppContext);
  const { t } = useTranslation();
  const handleLanguageChange = useCallback(
    (e) => {
      const inpLocale = e.target.value;
      setLanguage(inpLocale);
      apiAgent.session.setLocale({ locale: inpLocale });
    },
    [setLanguage]
  );
  return (
    <Box
      sx={{
        background: "black",
        width: "100%",
        height: "40px",
        zIndex: 100,
        position: "fixed",
        bottom: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 2,
          gap: 3,
          width: "calc(100vw - 160px)",
        }}
      >
        <Typography variant="caption" sx={{ color: "white", px: "2px" }}>
          {t("language")}
        </Typography>
        <FormControl>
          <Select
            labelId="languaje-selector-label"
            id="demo-simple-select"
            value={language}
            onChange={handleLanguageChange}
            sx={{ color: "white" }}
            size="small"
          >
            <MenuItem value="es">Español</MenuItem>
            <MenuItem value="en">English</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="caption" sx={{ color: "white" }}>
          v{version}
        </Typography>
      </Box>
      <img
        style={{ height: 88, position: "absolute", bottom: 8, right: 40 }}
        src={logoFooter}
        alt="Freddo logo"
      ></img>
    </Box>
  );
};

export default FooterComponent;
