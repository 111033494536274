import { useMemo, useState, useEffect, useCallback, useContext } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataGrid from "../../components/DataGrid";
import {
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CreateIcon from "@mui/icons-material/Create";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserEditor from "../../components/userEditor";
import { useSnackbar } from "notistack";
import apiAgent from "../../apiAgent";
import { t } from "i18next";
import { AppContext } from "../../AppContextProvider";
import { confirmWrapper } from "../../components/confirmDialog";
function getFullName(params) {
  return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}
function getRoleName(params) {
  return params.row.roles?.map((rol) => t(rol.name)).join(" - ");
}
function getFranchiseValue({ row }) {
  return row.franchiseId
    ? [row.franchiseId, row.franchiseName].join(" - ")
    : "";
}
const Users = () => {
  const { t } = useTranslation();
  const { renderIfPermission } = useContext(AppContext);

  const [expandedUserEditor, setExpandedUserEditor] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState();
  const [editorErrors, setEditorErrors] = useState();
  const [loading, setLoading] = useState(true);
  const [franchises, setFranchises] = useState();
  const getfranchises = useCallback(
    (props) =>
      apiAgent.franchises
        .get({ ...props, bypassPagination: true, pageSize: 9999999 })
        .then((v) => {
          setFranchises(
            v.data.map((f) => ({
              ...f,
              labelName: `${f.franchiseId} ${f.franchiseName} - ${f.countryName}, ${f.countryStateName}`,
            }))
          );
          return v;
        }),
    []
  );

  const handleDelete = useCallback(async (user) => {
    if (!(await confirmWrapper())) return;
    apiAgent.users.delete(user).then(() => {
      setUsers((old) => ({
        ...old,
        data: old.data.filter((c) => c.userId !== user.userId),
      }));
    });
  }, []);
  const handleModify = useCallback((user) => {
    document.getElementById("user-editor")?.focus();
    setExpandedUserEditor({
      defaultValues: {
        ...user,
        rol: user.roles[0].name,
        franchise: user.franchiseId,
      },
    });
  }, []);
  const handleChange = useCallback(
    (panel) => (event, isExpanded) => {
      setExpandedUserEditor(isExpanded ? panel : false);
    },
    []
  );
  const handleEditorRejection = useCallback(
    (rej) => {
      const errors = rej?.response?.data?.errors;
      setEditorErrors(errors);
    },
    [setEditorErrors]
  );
  const handleUserSubmit = useCallback(
    async ({ roles, country, countryState, ...userFromEditor }) => {
      if (!(await confirmWrapper())) return;
      const payload = {
        ...userFromEditor,
        countryId: country?.countryId,
        countryStateId: countryState.countryStateId,
      };
      if (userFromEditor.userId) {
        apiAgent.users.update(payload).then((updated) => {
          setExpandedUserEditor(false);
          setUsers((old) => ({
            ...old,
            data: old.data.map((c) =>
              c.userId === updated.userId ? updated : c
            ),
          }));
          enqueueSnackbar(t("userUpdateSuccess"), { variant: "success" });
        }, handleEditorRejection);
      } else {
        apiAgent.users.create(payload).then((newUser) => {
          setExpandedUserEditor(false);
          setUsers((old) => ({ ...old, data: [newUser, ...old.data] }));
          enqueueSnackbar(t("userCreationSuccess"), { variant: "success" });
        }, handleEditorRejection);
      }
    },
    [enqueueSnackbar, t, handleEditorRejection]
  );

  const columns = useMemo(
    () => [
      { field: "userId", headerName: "ID", flex: 1 },
      { field: "username", headerName: t("username"), flex: 5 },
      { field: "email", headerName: t("email"), flex: 4 },
      {
        field: "fullName",
        headerName: t("name"),
        valueGetter: getFullName,
        flex: 2,
      },

      {
        field: "roles",
        headerName: t("role"),
        valueGetter: getRoleName,
        flex: 2,
      },
      {
        field: "franchiseId",
        headerName: t("franchise"),
        flex: 2,
        valueGetter: getFranchiseValue,
      },
      { field: "countryName", headerName: t("country"), flex: 2 },
      { field: "countryStateName", headerName: t("state"), flex: 2 },
      { field: "city", headerName: t("city"), flex: 2 },

      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => handleDelete(params.row)}
            label={t("delete")}
          />,
          <GridActionsCellItem
            onClick={() => handleModify(params.row)}
            icon={<CreateIcon />}
            label={t("edit")}
            disabled={params?.row?.roles[0].name === "super-admin"}
            showInMenu
          />,
        ],
      },
    ],
    [t, handleDelete, handleModify]
  );
  useEffect(() => {
    setLoading(true);
    apiAgent.users
      .getAll()
      .then(setUsers)
      .then(() => setLoading(false));
    getfranchises();
  }, [getfranchises]);

  return (
    <Container maxWidth="lg">
      <Accordion
        expanded={!!expandedUserEditor}
        onChange={handleChange(true)}
        sx={{ my: 2 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <AddCircle color="primary" />

            <Typography sx={{ color: "text.secondary" }}>
              {t(expandedUserEditor?.defaultValues ? "edit" : "createNewUser")}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "40em",
                maxWidth: "95%",
              }}
            >
              {expandedUserEditor && (
                <UserEditor
                  onCancel={() => setExpandedUserEditor(false)}
                  onSubmit={handleUserSubmit}
                  setErrors={setEditorErrors}
                  defaultValues={expandedUserEditor?.defaultValues}
                  errors={editorErrors}
                  franchises={franchises}
                />
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {renderIfPermission("read user") && (
        <DataGrid
          sx={{ width: "100%", minHeight: "50vh" }}
          columns={columns}
          getRowId={(row) => row.userId}
          loading={loading}
          rows={users?.data || []}
        />
      )}
    </Container>
  );
};
export default Users;
