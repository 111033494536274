import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { DataGrid, esES } from "@mui/x-data-grid";
const supportedLocalesDataGrid = {
  es: esES,
};
const DataGridLocalized = (props) => {
  const { language } = useContext(AppContext);

  return (
    <DataGrid
      localeText={
        supportedLocalesDataGrid[language]?.components.MuiDataGrid.defaultProps
          .localeText
      }
      {...props}
    />
  );
};

export default DataGridLocalized;
