import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
  palette: {
    primary: {
      main: "#318cdd",
    },
    secondary: {
      main: "#28516c",
    },

    default: {
      main: "#bdbdbd",
    },
    custom: {
      main: "#28516c",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
