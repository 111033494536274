import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { statesIcons } from "../../config";

export const CustomizedTimeline = React.memo(({ history }) => {
  const { t } = useTranslation();
  return (
    <Timeline position="alternate">
      {history.map((state, i) => (
        <React.Fragment key={i}>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {t("dateFormat", {
                val: new Date(state.created_at),
                interpolation: { escapeValue: false },
                timeStyle: "short",
                dateStyle: "short",
              })}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color={statesIcons[state.stateName].color}>
                {statesIcons[state.stateName].icon}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography
                variant="h6"
                component="span"
                sx={{ wordBreak: "break-all" }}
              >
                {t("requests-REQUEST_STATE-" + state.stateName)}
              </Typography>
              <br />
              <Typography variant="caption">{state.username}</Typography>
            </TimelineContent>
          </TimelineItem>
        </React.Fragment>
      ))}
    </Timeline>
  );
});
