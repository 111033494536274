const set = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};
const get = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return localStorage.getItem(key);
  }
};
const remove = (key) => {
  return localStorage.removeItem(key);
};
const clear = () => {
  return localStorage.clear();
};

export const ClientStorage = {
  set,
  get,
  remove,
  clear,
};
