import { Box, Button, Container, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import apiAgent from "../../apiAgent";
import { AppContext } from "../../AppContextProvider";
import AvatarWithUserInitials from "../../components/AvatarWithUserInitials";
import { RHTextField } from "../../components/inputs/RHTextField";
import { confirmWrapper } from "../../components/confirmDialog";

const Profile = () => {
  const { state, setState, renderIfStrictRole } = useContext(AppContext);
  const user = useMemo(() => state?.session?.user, [state?.session?.user]);
  const methods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const { control, handleSubmit, setValue, getValues, setError } = methods;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (user) {
      for (let prop in user) {
        setValue(prop, user[prop]);
      }
    }
  }, [user, setValue]);
  const onSubmit = useCallback(
    async (v) => {
      if (!(await confirmWrapper())) return;
      const { roles, permissions, ...payload } = v;
      apiAgent.users.update(payload).then(
        (user) => {
          enqueueSnackbar(t("profileUpdateSuccess"));
          setState((old) => ({
            ...old,
            session: { ...old.session, user: { ...old.session.user, ...user } },
          }));
        },
        (rej) => {
          enqueueSnackbar(t("profileUpdateError"), { variant: "error" });
          const errors = rej.response.data.errors;
          Object.keys(errors).forEach((name) => {
            setError(name, { message: errors[name].join(" - ") });
          });
        }
      );
    },
    [t, enqueueSnackbar, setState, setError]
  );
  return (
    <>
      <Container maxWidth="sm">
        <Box
          sx={{
            width: "100%",
            border: "1px solid lightgray",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1em",
              gap: 3,
              backgroundColor: "gray",
            }}
          >
            <AvatarWithUserInitials />
            <Typography sx={{ color: "white" }}>
              {user?.firstName} {user?.lastName}
            </Typography>
            {renderIfStrictRole("franchise") && (
              <Typography sx={{ color: "white" }} variant="caption">
                {user.franchiseName}
              </Typography>
            )}
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "2em",
              }}
            >
              <Box
                sx={{
                  maxWidth: "500px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: "1em",
                }}
              >
                <RHTextField
                  control={control}
                  name="firstName"
                  label={t("firstName")}
                  fullWidth
                />
                <RHTextField
                  control={control}
                  name="lastName"
                  label={t("lastName")}
                  fullWidth
                />
                <RHTextField
                  control={control}
                  name="email"
                  label={t("email")}
                  fullWidth
                />

                <Typography variant="h5">{t("resetPassword")}</Typography>
                <RHTextField
                  control={control}
                  name="password"
                  type="password"
                  label={t("password")}
                  fullWidth
                />
                <RHTextField
                  control={control}
                  name="cpassword"
                  type="password"
                  label={t("passwordConfirm")}
                  rules={{
                    validate: (value) => {
                      const { password } = getValues();
                      return password
                        ? value
                          ? password === value || t("passwordsShouldMatch")
                          : t("required")
                        : true;
                    },
                  }}
                  fullWidth
                />
              </Box>
            </Box>
            <Button
              size="large"
              sx={{ py: "3em" }}
              fullWidth
              type="submit"
              color="primary"
            >
              {t("save")}
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
};
export default Profile;
