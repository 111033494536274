import React, { useEffect, useContext, useMemo, useState } from "react";
import { AppContext } from "../../AppContextProvider";
import { useForm } from "react-hook-form";
import { RHTextField } from "../inputs/RHTextField";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RHFAutocomplete, RHSelect } from "../inputs/RHSelect";
import { basicUserFields } from "../../config";

const defaultRolId = "franchise";
const UserEditor = ({
  onSubmit = () => {},
  onCancel = () => {},
  errors,
  defaultValues = { rol: defaultRolId },
  franchises,
}) => {
  const { getRoles, getStates, getCountries, state, renderIfRole } =
    useContext(AppContext);
  const { t } = useTranslation();
  const methods = useForm({ defaultValues });
  const { handleSubmit, control, watch, setValue, setError } = methods;
  const [loading, setLoading] = useState({});
  const countries = useMemo(() => state?.countries, [state?.countries]);
  const roles = useMemo(
    () =>
      state?.roles.filter((r) =>
        r.only
          ? r.only.find((r) => r === state?.session?.user?.roles[0].name)
          : true
      ),
    [state?.roles, state?.session?.user?.roles]
  );
  const [states, setStates] = useState();
  const watchCountry = watch(
    "country",
    countries?.find((c) => c.countryId === state?.session?.user?.countryId)
  );
  const watchRole = watch("rol", defaultValues.rol);
  const watchState = watch("countryState");
  const filteredFranchises = useMemo(() => {
    return (
      watchState &&
      franchises.filter((f) => f.countryStateId === watchState.countryStateId)
    );
  }, [watchState, franchises]);
  const requiredFieldsContain = useMemo(
    () => (name) => {
      const rol = roles.find((r) => r.name === watchRole);
      return (
        rol?.requiredFields?.includes(name) || basicUserFields.includes(name)
      );
    },
    [watchRole, roles]
  );

  useEffect(() => {
    getCountries();
    getRoles();
  }, [getCountries, getRoles]);

  useEffect(() => {
    if (watchCountry) {
      setLoading((old) => ({ ...old, states: true }));
      getStates(watchCountry).then((states) => {
        setLoading((old) => ({ ...old, states: undefined }));
        setStates(states);
        if (!defaultValues?.id) {
          setValue("state", defaultValues.state || "");
        }
      });
    }
  }, [
    watchCountry,
    getStates,
    setValue,
    defaultValues?.id,
    defaultValues?.state,
  ]);
  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((name) => {
        setError(name, { message: errors[name].join(" - ") });
      });
    }
  }, [errors, setError]);
  const gap = "1em";
  return (
    <>
      <form
        id="user-editor"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", gap }}>
          <RHSelect
            name="rol"
            control={control}
            options={roles}
            valueKey="name"
            labelKey="name"
            renderValue={(value) => t(value)}
            rules={{ required: t("required") }}
            label={t("role")}
            disabled={!roles}
            fullWidth
          />
        </Box>

        <Box sx={{ display: "flex", gap }}>
          <RHTextField
            name="username"
            control={control}
            label={t("username")}
            rules={{ required: t("required") }}
            fullWidth
          />
          <RHTextField
            name="password"
            control={control}
            label={t("password")}
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", gap }}>
          <RHTextField
            name="email"
            control={control}
            label={t("email")}
            rules={{ required: t("required") }}
            fullWidth
          />
          {requiredFieldsContain("telephone") && (
            <RHTextField
              name="telephone"
              control={control}
              label={t("telephone")}
              fullWidth
            />
          )}
        </Box>
        <Box sx={{ display: "flex", gap }}>
          <RHTextField
            name="firstName"
            control={control}
            label={t("firstName")}
            rules={{ required: t("required") }}
            fullWidth
          />
          <RHTextField
            name="lastName"
            control={control}
            label={t("lastName")}
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", gap }}>
          {requiredFieldsContain("address") && (
            <RHTextField
              name="address"
              control={control}
              label={t("address")}
              rules={{ required: t("required") }}
              fullWidth
            />
          )}
          {requiredFieldsContain("address") && (
            <RHTextField
              name="postalCode"
              control={control}
              label={t("postalCode")}
              rules={{ required: t("required") }}
              fullWidth
            />
          )}
        </Box>
        <Box sx={{ display: "flex", gap }}>
          {requiredFieldsContain("city") && (
            <RHTextField
              name="city"
              control={control}
              label={t("city")}
              rules={{ required: t("required") }}
              fullWidth
            />
          )}
        </Box>
        <Box sx={{ display: "flex", gap, mb: 4 }}>
          {requiredFieldsContain("country") && renderIfRole("super-admin") && (
            <RHFAutocomplete
              name="country"
              control={control}
              options={countries || []}
              rules={{ required: t("required") }}
              defaultValue={
                defaultValues.countryId
                  ? {
                      countryId: defaultValues.countryId,
                      countryName: defaultValues.countryName,
                    }
                  : null
              }
              label={t("country")}
              getOptionLabel={(option) => option?.countryName || ""}
              disabled={!countries}
              fullWidth={true}
              sx={{ width: renderIfRole("super-admin") ? "50%" : "100%" }}
              isOptionEqualToValue={(option, value) =>
                value === "" || option.countryId === value.countryId
              }
            />
          )}
          {requiredFieldsContain("state") && (
            <RHFAutocomplete
              name="countryState"
              control={control}
              options={states || []}
              disabled={loading.states}
              defaultValue={
                defaultValues.countryStateName
                  ? {
                      countryStateName: defaultValues.countryStateName,
                      countryStateId: defaultValues.countryStateId,
                    }
                  : null
              }
              rules={{ required: t("required") }}
              label={t("state")}
              getOptionLabel={(option) => option?.countryStateName || ""}
              fullWidth={true}
              sx={{ width: renderIfRole("super-admin") ? "50%" : "100%" }}
              isOptionEqualToValue={(option, value) => {
                return (
                  value === "" || option.countryStateId === value.countryStateId
                );
              }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex" }}>
          {requiredFieldsContain("franchise") && (
            <RHSelect
              name="franchise"
              control={control}
              options={filteredFranchises || franchises}
              valueKey="franchiseId"
              labelKey="labelName"
              rules={{ required: t("required") }}
              label={t("franchise")}
              disabled={!watchState}
              fullWidth
            />
          )}
        </Box>

        <Box
          className="buttons"
          sx={{ display: "flex", gap, py: 5, justifyContent: "center" }}
        >
          <Button
            type="button"
            color="secondary"
            onClick={onCancel}
            variant="contained"
            sx={{ width: "48%" }}
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ width: "48%" }}
          >
            {t("accept")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default UserEditor;
