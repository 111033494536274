import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataGrid from "../../components/DataGrid";
import {
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ReadMore from "@mui/icons-material/ReadMore";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownload from "@mui/icons-material/FileDownload";
import { useSnackbar } from "notistack";

import RequestEditor from "../../components/requestEditor";
import apiAgent from "../../apiAgent";
import { AppContext } from "../../AppContextProvider";
import { confirmWrapper } from "../../components/confirmDialog";
import { statesIcons, statesTransitions } from "../../config";
import { dateTimeZero } from "../../constants";

const Requests = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [requests, setRequests] = useState({ data: [] });
  const [editorDefaultValues, setEditorDefaultValues] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [requestsLoading, setRequestsLoading] = useState();
  const [page, setPage] = useState(0);
  const { renderIfRole, state } = useContext(AppContext);

  const handleChange = useCallback(
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      setEditorDefaultValues(undefined);
    },
    []
  );
  const handleRequestSubmit = useCallback(
    async (request) => {
      if (!(await confirmWrapper())) return;
      if (!request.requestHeaderId) {
        apiAgent.request.create(request).then((data) => {
          setRequests((old) => ({ ...old, data: [data, ...old.data] }));
          setExpanded(false);
          enqueueSnackbar(t("requestSubmitted"), { variant: "success" });
        });
      } else {
        const { requestHeaderId, dateFrom, dateTo, products, comments } = {
          ...editorDefaultValues,
          ...request,
        };
        apiAgent.request
          .update({
            requestHeaderId,
            dateFrom,
            dateTo,
            products: products.map(
              ({ requestItemId, product, qty, requestItemQty }) => ({
                requestItemId,
                product,
                qty: Number.parseInt(qty || requestItemQty),
              })
            ),
            comments,
          })
          .then((data) => {
            setRequests((old) => ({
              ...old,
              data: old.data.map((c) =>
                c.requestHeaderId === requestHeaderId ? request : c
              ),
            }));
            setExpanded(false);
            setEditorDefaultValues(undefined);
          });
      }
    },
    [enqueueSnackbar, t, editorDefaultValues]
  );
  const handleUpdateState = useCallback(
    async (request, { state }) => {
      if (!(await confirmWrapper())) return;
      apiAgent.request.updateState(request, { state }).then(
        (payload) => {
          setRequests((old) => ({
            ...old,
            data: old.data.map((d) =>
              d.requestHeaderId === request.requestHeaderId ? payload : d
            ),
          }));
        },
        (rej) => {
          const errors = rej?.response?.data;

          enqueueSnackbar(
            errors?.map((v) => t("requests-" + v)).join(". ") ||
              t("UPDATE_STATE_FAILURE"),
            {
              variant: "error",
            }
          );
        }
      );
    },
    [enqueueSnackbar, t]
  );
  const handleDelete = useCallback(async (request) => {
    if (!(await confirmWrapper())) return;

    apiAgent.request.delete(request).then(() =>
      setRequests((old) => ({
        ...old,
        data: old.data.filter(
          (d) => d.requestHeaderId !== request.requestHeaderId
        ),
      }))
    );
  }, []);
  const actions = useMemo(
    () =>
      ({ row }) => {
        const actions = [
          <GridActionsCellItem
            icon={<ReadMore />}
            label={t("view")}
            onClick={() => {
              setEditorDefaultValues(row);
              setExpanded("view");
            }}
            showInMenu
          />,
        ];
        if (renderIfRole("franchise") && row.stateName === "STARTED") {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon color="secondary" />}
              onClick={() => handleDelete(row)}
              label={t("delete")}
            />
          );
        }
        const roles = state?.session?.user?.roles;
        roles.forEach((rol) => {
          const rolTransitions = statesTransitions[row.stateName];
          if (!rolTransitions) return;
          const stateRolTransitions = rolTransitions[rol.name];
          if (stateRolTransitions) {
            stateRolTransitions.forEach((state) => {
              actions.push(
                <GridActionsCellItem
                  icon={statesIcons[state]?.icon}
                  label={t("requests-REQUEST_STATE-" + state)}
                  onClick={() => {
                    handleUpdateState(row, { state });
                  }}
                  showInMenu
                />
              );
            });
          }
        });
        if (row.stateName === "SUCCESSFUL") {
          actions.push(
            <GridActionsCellItem
              icon={<FileDownload />}
              label={t("deliveryNote")}
              onClick={() => {
                window.open(apiAgent.request.getDeliveryNote(row), "_blank");
              }}
              showInMenu
            />
          );
        }
        if (row.stateName === "IN_PROCESS" && renderIfRole("logistics")) {
          actions.push(
            <GridActionsCellItem
              icon={<FileDownload />}
              label={t("workOrder")}
              onClick={() => {
                window.open(apiAgent.request.getWorkOrder(row), "_blank");
              }}
              showInMenu
            />
          );
        }
        return actions;
      },
    [
      handleDelete,
      t,
      state?.session?.user?.roles,
      handleUpdateState,
      renderIfRole,
    ]
  );
  const columns = useMemo(
    () => [
      { field: "requestHeaderId", headerName: t("requestNumber"), flex: 1 },
      {
        field: "franchiseName",
        headerName: t("franchise"),
        flex: 3,
      },
      {
        field: "stateName",
        renderCell: ({ value }) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <span style={{ color: "gray" }}>{statesIcons[value].icon}</span>
            <Typography variant="caption" color="default" sx={{ pb: "4px" }}>
              {t("requests-REQUEST_STATE-" + value)}
            </Typography>
          </Box>
        ),
        headerName: t("state"),
        flex: 2,
      },
      {
        field: "dateFrom",
        headerName: t("dateFrom"),
        valueGetter: ({ row }) =>
          t("dateFormat", {
            val: dateTimeZero(row.dateFrom),
            interpolation: { escapeValue: false },
            dateStyle: "short",
          }),
        flex: 1,
      },
      {
        field: "dateTo",
        valueGetter: ({ row }) =>
          t("dateFormat", {
            val: dateTimeZero(row.dateTo),
            interpolation: { escapeValue: false },
            dateStyle: "short",
          }),
        headerName: t("dateTo"),
        flex: 1,
      },
      {
        field: "created_at",
        valueGetter: ({ row }) =>
          t("dateFormat", {
            val: row.created_at.split("T").join(" ").substr(0, 19),
          }),
        headerName: t("dateCreated"),
        flex: 2,
      },
      {
        field: "actions",
        type: "actions",
        flex: 1,
        getActions: actions,
      },
    ],
    [t, actions]
  );
  useEffect(() => {
    setRequestsLoading(true);
    apiAgent.request
      .get({ page: page + 1 })
      .then((payload) => {
        setRequests(payload);
        return payload;
      })
      .then(() => setRequestsLoading(false));
  }, [page]);

  return (
    <Container maxWidth="lg">
      <Accordion
        expanded={!!expanded}
        onChange={handleChange("createRequest")}
        sx={{ my: 2 }}
        disabled={renderIfRole("admin", "logistics") && !editorDefaultValues}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <AddCircle color="primary" />
            <Typography sx={{ color: "text.secondary" }}>
              {t(expanded || "createRequest")}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",

                width: "95%",
              }}
            >
              {expanded && (
                <RequestEditor
                  show={expanded}
                  defaultValues={editorDefaultValues}
                  onEstimatedDeliveryDateChange={(data) =>
                    setRequests((old) => ({
                      ...old,
                      data: old.data.map((c) =>
                        c.requestHeaderId === data.requestHeaderId
                          ? { ...c, ...data }
                          : c
                      ),
                    }))
                  }
                  onCancel={() => {
                    setExpanded(false);
                    setEditorDefaultValues(undefined);
                  }}
                  onSubmit={handleRequestSubmit}
                />
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <DataGrid
        sx={{ width: "100%", minHeight: "50vh" }}
        rows={requests?.data}
        rowCount={requests?.total || 0}
        loading={requestsLoading}
        pagination
        page={requests?.current_page}
        pageSize={requests?.per_page || 10}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        columns={columns}
        getRowId={(row) => row.requestHeaderId}
        rowsPerPageOptions={[30]}
      />
    </Container>
  );
};
export default Requests;
