import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const RHTextField = ({
  name,
  control,
  rules,
  label,
  fullWidth,
  extOnChange,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <>
        <TextField
          label={label}
          value={value || ""}
          onChange={(...params) => {
            onChange(...params);
            extOnChange && extOnChange(...params);
          }}
          fullWidth={fullWidth}
          error={!!error}
          helperText={error?.message || ""}
          {...rest}
          sx={{ minHeight: "100px", ...rest?.sx }}
        />
      </>
    )}
  />
);
