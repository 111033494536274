import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataGrid from "../../components/DataGrid";
import {
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  ButtonGroup,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MoneyIcon from "@mui/icons-material/Money";
import CreateIcon from "@mui/icons-material/Create";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import ProductEditor from "../../components/productEditor";
import apiAgent from "../../apiAgent";
import { AppContext } from "../../AppContextProvider";
import { ProductPrices } from "./productPrices";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { confirmWrapper } from "../../components/confirmDialog";

const productPrimaryKey = "productId";
const Products = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [editingProduct, setEditingProduct] = useState();
  const {
    state,
    setState,
    getProducts,
    renderIfPermission,

    getImgsHost,
    clearCacheKey,
    renderIfStrictRole,
  } = useContext(AppContext);
  const products = useMemo(() => state?.products || [], [state?.products]);
  const { enqueueSnackbar } = useSnackbar();
  const [editorErrors, setEditorErrors] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedProductPrices, setSelectedProductPrices] = useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    !isExpanded && setEditingProduct(undefined);
    setEditorErrors(undefined);
  };
  const handleProductSubmit = useCallback(
    async (data) => {
      if (!(await confirmWrapper())) return;
      clearCacheKey("products");
      if (editingProduct) {
        const { img, publicImgSrc, ...updatePayload } = data;
        apiAgent.products.update(updatePayload).then(
          (updated) => {
            getProducts();
            setExpanded(false);
            setEditingProduct(undefined);
            setEditorErrors(undefined);
            enqueueSnackbar(t("productEdited"), { variant: "success" });
          },
          (rej) => {
            const errors = rej?.response?.data?.errors;
            if (errors) {
              setEditorErrors(errors);
              enqueueSnackbar(t("checkValidations"), { variant: "warning" });
            }
          }
        );
      } else {
        const formData = new FormData();
        for (const key in data) {
          if (data[key]) {
            formData.append(key, data[key]);
          }
        }
        apiAgent.products.create(formData).then(
          ({ data: newProduct }) => {
            getProducts();
            setExpanded(false);
            setEditingProduct(undefined);
            setEditorErrors(undefined);
            enqueueSnackbar(t("productCreated"), { variant: "success" });
          },
          (rej) => {
            const errors = rej?.response?.data?.errors;
            if (errors) {
              setEditorErrors(errors);
              enqueueSnackbar(t("checkValidations"), { variant: "warning" });
            }
          }
        );
      }
    },
    [editingProduct, enqueueSnackbar, t, clearCacheKey, getProducts]
  );
  const handleModify = useCallback((value) => {
    setExpanded("productEditor");
    setEditingProduct({ ...value, category: value.categoryId });
  }, []);
  const handleDelete = useCallback(
    async (product) => {
      if (!(await confirmWrapper())) return;
      apiAgent.products.delete(product).then(() => {
        setState((old) => ({
          ...old,
          products: old.products.filter(
            (p) => p[productPrimaryKey] !== product[productPrimaryKey]
          ),
        }));
      });
    },
    [setState]
  );
  const actions = useMemo(
    () =>
      ({ row }) =>
        [
          {
            if: renderIfPermission("delete product"),
            el: (
              <GridActionsCellItem
                icon={<DeleteIcon color="secondary" />}
                onClick={() => handleDelete(row)}
                label={t("delete")}
              />
            ),
          },
          {
            if: renderIfPermission("update product"),
            el: (
              <GridActionsCellItem
                onClick={() => handleModify(row)}
                icon={<CreateIcon />}
                label={t("edit")}
                showInMenu
              />
            ),
          },
          {
            if: renderIfPermission("update product price"),
            el: (
              <GridActionsCellItem
                onClick={() => setSelectedProductPrices(row)}
                icon={<MoneyIcon />}
                label={t("prices")}
                showInMenu
              />
            ),
          },
        ]
          .filter((c) => c.if)
          .map((c) => c.el),
    [renderIfPermission, handleDelete, handleModify, t]
  );
  const columns = useMemo(
    () => [
      { field: "productId", headerName: "ID", flex: 1 },
      {
        field: "img",
        headerName: t("image"),
        flex: 1,
        renderCell: ({ value }) =>
          value ? (
            <img
              src={getImgsHost() + value}
              width="90"
              alt={t("product")}
              height="90"
              style={{ objectFit: "contain" }}
            />
          ) : (
            <></>
          ),
      },
      {
        field: "productCategoryName",
        headerName: t("category"),
        flex: 2,
        valueGetter: ({ row }) => t(row.productCategoryName),
      },
      { field: "name", headerName: t("name"), flex: 2 },
      { field: "sku", headerName: t("sku"), flex: 1 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: actions,
      },
    ],
    [t, actions, getImgsHost]
  );
  useEffect(() => {
    getProducts().then(() => setLoading(false));
  }, [getProducts]);
  const handleMassiveExport = useCallback(() => {
    setLoading("exporting");
    apiAgent.products.prices.massive.logistics.get().then((response) => {
      apiAgent.downloadFile(response, "Freddo-Prices.xlsx");
      setLoading(undefined);
    });
  }, []);
  const handleMassiveImport = useCallback(
    (e) => {
      if (e.target?.files?.length === 1) {
        setLoading("importing");
        const file = e.target.files[0];
        apiAgent.products.prices.massive.logistics
          .post(file)
          .then(
            (res) => {
              setLoading(undefined);
              const response = res.data;

              if (response.transactionVaultValues?.length > 0) {
                enqueueSnackbar(t("pricesUpdateSuccess", response), {
                  variant: "success",
                });
              }
              if (response.error?.length > 0) {
                enqueueSnackbar(t("pricesUpdateError", response.error), {
                  variant: "error",
                });
              }
            },
            (rej) => {
              const response = rej?.response?.data?.message;
              setLoading(undefined);
              enqueueSnackbar(t(response), {
                variant: "error",
              });
            }
          )
          .catch(() => {
            setLoading(undefined);
            enqueueSnackbar(t("error"));
          });
      }
    },
    [enqueueSnackbar, t]
  );
  return (
    <Container maxWidth="lg">
      {selectedProductPrices ? (
        <ProductPrices
          product={selectedProductPrices}
          onClose={() => setSelectedProductPrices(undefined)}
        />
      ) : (
        <>
          {renderIfStrictRole("admin") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "flex-end",
              }}
            >
              <ImportExportIcon color="primary" />
              <ButtonGroup variant="text" sx={{ mb: "10px" }}>
                <Button
                  onClick={handleMassiveExport}
                  disabled={loading === "exporting"}
                >
                  {t("export")}
                </Button>
                <Button component="label" disabled={loading === "importing"}>
                  {t("import")}
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleMassiveImport}
                  />
                </Button>
              </ButtonGroup>
            </Box>
          )}

          {renderIfPermission("create product", "update product") &&
            !renderIfStrictRole("super-admin") && (
              <Accordion
                expanded={expanded === "productEditor"}
                onChange={handleChange("productEditor")}
                sx={{ my: 2 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                    <AddCircle color="primary" />
                    <Typography sx={{ color: "text.secondary" }}>
                      {editingProduct
                        ? t("edit") + " " + t("product")
                        : t("createNewProduct")}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",

                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "40em",
                        maxWidth: "95%",
                      }}
                    >
                      {expanded && (
                        <ProductEditor
                          onCancel={() => {
                            setExpanded(false);
                            setEditingProduct(undefined);
                          }}
                          onSubmit={handleProductSubmit}
                          defaultValues={editingProduct}
                          errors={editorErrors}
                          setErrors={setEditorErrors}
                        />
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}

          <DataGrid
            sx={{ width: "100%", minHeight: "50vh" }}
            columns={columns}
            loading={loading === true}
            getRowId={(row) => row[productPrimaryKey]}
            rows={products}
          />
        </>
      )}
    </Container>
  );
};
export default Products;
