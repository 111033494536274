import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Close";
import AddCircle from "@mui/icons-material/AddCircle";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import DataGridLocalized from "../../components/DataGrid";
import apiAgent from "../../apiAgent";
import { useSnackbar } from "notistack";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FranchiseEditor } from "../../components/franchiseEditor";
import { confirmWrapper } from "../../components/confirmDialog";

function EditToolbar({ setFranchises, editing, expanded, setExpanded }) {
  const { t } = useTranslation();

  return (
    <GridToolbarContainer sx={{ px: "3em", py: "1em" }}>
      <Accordion
        expanded={expanded}
        onChange={setExpanded}
        sx={{ width: "100%" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <AddCircle color="primary" />
            <Typography sx={{ color: "text.secondary" }}>
              {editing
                ? t("edit") + " " + t("franchise")
                : t("createNewFranchise")}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {expanded && (
            <FranchiseEditor
              onChangeExpanded={(v) => setExpanded(null, false)}
              onChange={setFranchises}
              defaultValues={editing}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </GridToolbarContainer>
  );
}

const itemKey = "categoryId";
export default function Franchises() {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [franchises, setFranchises] = useState([]);
  const [editing, setEditing] = useState();
  const [editorExpanded, setEditorExpanded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [paginationState, setPaginationState] = useState({
    page: 0,
    pageSize: 15,
  });
  const [loadingGrid, setLoadingGrid] = useState(false);
  const { t } = useTranslation();
  const handleRowEditStart = React.useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);
  const getfranchises = useCallback(
    (props = { ...paginationState }) => {
      setLoadingGrid(true);
      apiAgent.franchises.get({ ...props, page: props.page + 1 }).then((v) => {
        setLoadingGrid(false);
        setFranchises(v);
        return v;
      });
    },
    [paginationState]
  );
  const handleRowEditStop = React.useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleEditClick = React.useCallback(
    (franchise) => () => {
      setEditing(franchise);
      setEditorExpanded(true);
    },
    []
  );

  const handleSaveClick = React.useCallback(
    (id) => () => {
      setRowModesModel((rowModesModel) => ({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View },
      }));
    },
    []
  );
  const handleExpandedChange = useCallback((event, isExpanded) => {
    setEditorExpanded(isExpanded);
    !isExpanded && setEditing(undefined);
    //setEditorErrors(undefined);
  }, []);
  const handleDeleteClick = React.useCallback(
    ({ franchiseId }) =>
      async () => {
        if (!(await confirmWrapper())) return;
        apiAgent.franchises.delete(franchiseId).then(
          () => {
            getfranchises();
          },
          (rej) => {
            const error = rej.response?.data?.error;
            const messageCode = [error.type, error.code].join("-");
            if (error) {
              enqueueSnackbar([t(messageCode), messageCode].join(". "), {
                variant: "warning",
              });
            }
          }
        );
      },
    [enqueueSnackbar, t, getfranchises]
  );

  const handleCancelClick = React.useCallback(
    (id) => () => {
      setRowModesModel((rowModesModel) => ({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));

      const editedRow = franchises?.data?.find((row) => row[itemKey] === id);
      if (editedRow?.isNew) {
        setFranchises((franchises) =>
          franchises.filter((row) => row[itemKey] !== id)
        );
      }
    },
    [franchises, setFranchises]
  );

  const processRowUpdate = React.useCallback((newRow) => {}, []);

  const columns = React.useMemo(
    () => [
      {
        field: "franchiseId",
        headerName: t("id"),

        flex: 1,
      },
      {
        field: "franchiseName",
        headerName: t("name"),
        renderCell: ({ value }) => t(value),
        width: 180,
        editable: true,
        flex: 3,
      },
      {
        field: "city",
        headerName: t("city"),
        flex: 2,
      },
      {
        field: "countryStateName",
        headerName: t("state"),
        flex: 3,
      },
      {
        field: "countryName",
        headerName: t("country"),
        flex: 2,
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("actions"),
        width: 100,
        cellClassName: "actions",
        flex: 2,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(row.franchiseId)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(row.franchiseId)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(row)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(row)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [
      handleCancelClick,
      handleDeleteClick,
      handleEditClick,
      handleSaveClick,
      rowModesModel,
      t,
    ]
  );
  React.useEffect(() => {
    getfranchises({ ...paginationState });
  }, [getfranchises, paginationState]);
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGridLocalized
        rows={franchises?.data || []}
        columns={columns}
        editMode="row"
        rowCount={franchises?.total}
        loading={loadingGrid}
        pagination
        page={paginationState.page}
        pageSize={paginationState.pageSize}
        paginationMode="server"
        onPageChange={(newPage) =>
          setPaginationState((old) => ({ ...old, page: newPage }))
        }
        onPageSizeChange={(v) =>
          setPaginationState((old) => ({ ...old, pageSize: v }))
        }
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        getRowId={(row) => row.franchiseId}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {
            setFranchises,
            setRowModesModel,
            expanded: editorExpanded,
            setExpanded: handleExpandedChange,
            editing,
            franchises,
          },
        }}
      />
    </Box>
  );
}
