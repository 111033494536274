import { Box, Button, Container, Grid } from "@mui/material";
import React, { useMemo, useCallback, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContextProvider";

const SettingsToolbar = React.memo(({ onChange, options }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const value = pathname.split("/").pop();
  const { renderIfPermission } = useContext(AppContext);
  const _options = useMemo(
    () =>
      options.filter((o) =>
        o.permission ? renderIfPermission(o.permission) : true
      ),
    [options, renderIfPermission]
  );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          paddingTop: "1em",
          justifyContent: "flex-start",
          mb: "1em",
        }}
      >
        {_options.map((option, i) => (
          <Button
            key={i}
            onClick={() => onChange(option)}
            color={value === option.path ? "primary" : "default"}
            sx={{ width: "15em", borderRadius: 0 }}
          >
            {t(option.label)}
          </Button>
        ))}
      </Box>
    </>
  );
});
const Settings = () => {
  // const { state } = useContext(AppContext);
  //const user = useMemo(() => state?.session?.user, [state?.session?.user]);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const settingsSections = useMemo(
    () => [
      {
        label: t("profile"),
        path: "profile",
      },
      {
        label: t("productCategories"),
        path: "productCategories",
        permission: "create product category",
      },
      {
        label: t("franchises"),
        path: "franchises",
        permission: "create franchise",
      },
      {
        label: t("countryConfiguration"),
        path: "countryConfiguration",
        permission: "update country configuration",
      },
    ],
    [t]
  );
  const handleTabChange = useCallback(
    (tab) => {
      navigate(tab.path);
    },
    [navigate]
  );

  return (
    <>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <SettingsToolbar
              options={settingsSections}
              onChange={handleTabChange}
            />
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Settings;
