import { Avatar } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { AppContext } from "../AppContextProvider";

const AvatarWithUserInitials = () => {
  const { state } = useContext(AppContext);
  const user = useMemo(() => state?.session?.user, [state?.session?.user]);
  const avatarInitials = useMemo(
    () =>
      user
        ? [
            user.firstName && user?.firstName[0],
            user.lastName && user?.lastName[0],
          ]
            .filter((part) => !!part)
            .join("")
            .toUpperCase()
        : "",
    [user]
  );

  return <Avatar variant="square" children={avatarInitials} />;
};
export default AvatarWithUserInitials;
