import { useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../../AppContextProvider";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { RHFAutocomplete } from "../inputs/RHSelect";
import { RHTextField } from "../inputs/RHTextField";
import apiAgent from "../../apiAgent";
import { useSnackbar } from "notistack";
import { confirmWrapper } from "../confirmDialog";

export const FranchiseEditor = ({
  onChangeExpanded,
  defaultValues,
  onChange,
}) => {
  const { state, getCountries, getStates } = useContext(AppContext);
  const { t } = useTranslation();

  const countries = useMemo(() => state?.countries, [state?.countries]);
  const methods = useForm({ defaultValues });
  const { handleSubmit, control, setError, watch, setValue, reset } = methods;
  const [loading, setLoading] = useState({});
  const watchCountry = watch("country");
  const { enqueueSnackbar } = useSnackbar();
  const [states, setStates] = useState();
  const [errors, setErrors] = useState();

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (watchCountry) {
      setLoading((old) => ({ ...old, states: true }));
      getStates(watchCountry).then((states) => {
        setStates(states);
        setLoading((old) => ({ ...old, states: undefined }));
        if (!defaultValues?.franchiseId) {
          setValue("state", defaultValues?.state || "");
        }
      });
    }
  }, [
    watchCountry,
    getStates,
    setValue,
    defaultValues?.franchiseId,
    defaultValues?.state,
  ]);
  const onSubmit = useCallback(
    async ({ country, countryState, ...v }) => {
      const payload = {
        ...v,
        countryId: country.countryId,
        countryStateId: countryState.countryStateId,
        countryStateName: undefined,
        countryName: undefined,
      };
      if (!(await confirmWrapper())) return;
      const promise = defaultValues
        ? apiAgent.franchises.update
        : apiAgent.franchises.create;
      promise(payload).then(
        (newRow) => {
          onChange((old) => ({
            ...old,
            data: defaultValues
              ? old.data.map((c) =>
                  c.franchiseId === v.franchiseId ? newRow : c
                )
              : [...old.data, newRow],
          }));
          onChangeExpanded(null, false);
          reset();
        },
        (rej) => {
          let errors = rej?.response?.data?.errors;
          if (errors) {
            setErrors(errors);
            enqueueSnackbar(t("checkValidations"), { variant: "error" });
            return;
          }
          errors = rej?.response?.data?.message;
          enqueueSnackbar(t("errors-" + errors), { variant: "error" });
        }
      );
    },
    [enqueueSnackbar, onChange, onChangeExpanded, reset, t, defaultValues]
  );
  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((name) => {
        setError(name, { message: errors[name].join(" - ") });
      });
    }
  }, [errors, setError]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Box>
        <Box sx={{ display: "flex", mb: 4, gap: 2 }}>
          <RHFAutocomplete
            name="country"
            control={control}
            options={countries || []}
            rules={{ required: t("required") }}
            label={t("country")}
            getOptionLabel={(option) => option?.countryName || ""}
            disabled={!countries}
            defaultValue={defaultValues || null}
            fullWidth={true}
            sx={{ width: "50%" }}
            isOptionEqualToValue={(option, value) =>
              value === "" || option.countryId === value.countryId
            }
          />
          <RHFAutocomplete
            name="countryState"
            control={control}
            options={states || []}
            disabled={!watchCountry || loading?.states}
            rules={{ required: t("required") }}
            defaultValue={defaultValues || null}
            label={t("state")}
            getOptionLabel={(option) => option?.countryStateName || ""}
            fullWidth={true}
            sx={{ width: "50%" }}
            isOptionEqualToValue={(option, value) =>
              value === "" || option.countryStateId === value.countryStateId
            }
          />
        </Box>
        <Box>
          <RHTextField
            control={control}
            label={t("name")}
            fullWidth
            rules={{ required: "Requerido" }}
            name="franchiseName"
          />
          <RHTextField
            control={control}
            label={t("city")}
            fullWidth
            rules={{ required: "Requerido" }}
            name="city"
          />
        </Box>
        <Button
          color="primary"
          startIcon={defaultValues ? <SaveIcon /> : <AddIcon />}
          type="submit"
          sx={{ marginTop: "1em" }}
        >
          {t(defaultValues ? "save" : "addItem")}
        </Button>
      </Box>
    </form>
  );
};
