import React, {
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useRef,
  useState,
} from "react";
import { AppContext } from "../../AppContextProvider";
import { Controller, useForm } from "react-hook-form";
import { RHTextField } from "../inputs/RHTextField";
import { Box, Button, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RHSelect } from "../inputs/RHSelect";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import apiAgent from "../../apiAgent";
import { useSnackbar } from "notistack";
import { confirmWrapper } from "../confirmDialog";

const ProductEditor = ({
  onSubmit = () => {},
  onCancel = () => {},
  defaultValues = undefined,
  errors,
  setErrors,
}) => {
  const { getProductCategories, state, getImgsHost } = useContext(AppContext);
  const { t } = useTranslation();
  const methods = useForm({ reValidateMode: "onChange" });
  const { handleSubmit, control, setValue, setError: setRHFErrors } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const imgInpRef = useRef();
  const imgPrvRef = useRef();
  const [imgSrc, setImgSrc] = useState(
    defaultValues?.img ? getImgsHost() + defaultValues.img : ""
  );
  const [imgUpdateErrors, setImgUpdateErrors] = useState();

  const productCategories = useMemo(
    () => state?.productCategories,
    [state?.productCategories]
  );
  const formatSubmission = useCallback(
    (values) => {
      onSubmit({
        ...values,
        categoryId: values.category,
        category: productCategories.find((c) => c.id === values.category),
      });
    },
    [productCategories, onSubmit]
  );
  const handleOnChangeImage = useCallback(
    (onChangeRHF) => async (e) => {
      const file = e.target.files[0];
      if (!file) return;
      if (defaultValues) {
        if (!(await confirmWrapper())) return;
        const fd = new FormData();
        fd.append("productId", defaultValues.productId);
        fd.append("img", file);

        apiAgent.products
          .updateImg(fd)
          .then(
            () => {
              setImgSrc(URL.createObjectURL(file));
              onChangeRHF(file);
              imgPrvRef.current.onload = function () {
                URL.revokeObjectURL(imgPrvRef.current.src);
              };
              enqueueSnackbar(t("updateSuccess"), { variant: "success" });
            },
            (rej) => {
              setImgUpdateErrors(rej?.response?.data?.errors);
              enqueueSnackbar(t("error"), { variant: "error" });
            }
          )
          .catch(() => {
            enqueueSnackbar(t("error"), { variant: "error" });
          });
      } else {
        setImgSrc(URL.createObjectURL(file));
        imgPrvRef.current.onload = function () {
          URL.revokeObjectURL(imgPrvRef.current.src);
        };
        onChangeRHF(file);
      }
    },
    [defaultValues, enqueueSnackbar, t]
  );

  useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);
  useEffect(() => {
    if (defaultValues) {
      for (let prop in defaultValues) {
        setValue(prop, defaultValues[prop]);
      }
    }
  }, [defaultValues, setValue]);
  useEffect(() => {
    if (errors || imgUpdateErrors) {
      const source = errors || imgUpdateErrors;
      Object.keys(source).forEach((name) => {
        setRHFErrors(name, { message: source[name].join(" - ") });
      });
      setErrors(undefined);
      setImgUpdateErrors(undefined);
    }
  }, [errors, setRHFErrors, setErrors, setImgUpdateErrors, imgUpdateErrors]);
  const gap = "1em";
  return (
    <>
      <form
        onSubmit={handleSubmit(formatSubmission)}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", gap }}>
          <RHTextField
            name="name"
            control={control}
            label={t("name")}
            rules={{ required: t("required") }}
            fullWidth
          />
          <RHTextField
            name="sku"
            control={control}
            label={t("sku")}
            rules={{ required: t("required") }}
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", gap }}>
          <RHTextField
            name="description"
            control={control}
            label={t("description")}
            rules={{ required: t("required") }}
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", gap }}>
          <RHTextField
            name="weight"
            control={control}
            label={t("weight")}
            type="number"
            step="0.01"
            min="0"
            rules={{ required: t("required") }}
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", gap }}>
          <RHSelect
            name="category"
            control={control}
            options={productCategories}
            valueKey="categoryId"
            labelKey="productCategoryName"
            renderValue={(value) => t(value)}
            rules={{ required: t("required") }}
            label={t("category")}
            disabled={!productCategories}
            fullWidth
          />
        </Box>

        <Controller
          control={control}
          name="img"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <input
                  onChange={handleOnChangeImage(onChange)}
                  type="file"
                  hidden={true}
                  ref={imgInpRef}
                />
                <Box
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(164,164,164,1) 0%, rgba(226,226,226,1) 100%)",
                    height: 300,
                    width: 300,
                  }}
                >
                  <img
                    src={imgSrc}
                    style={{
                      height: 300,
                      width: 300,
                      objectFit: "contain",
                      display: imgSrc ? "initial" : "none",
                    }}
                    ref={imgPrvRef}
                    alt={t("imageUpload")}
                  />
                </Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<UploadFileIcon />}
                  onClick={() => imgInpRef.current.click()}
                >
                  {t("uploadImage")}
                </Button>
              </Box>
              {error?.message && (
                <FormHelperText error={true}>{error.message}</FormHelperText>
              )}
            </>
          )}
        />

        <Box
          className="buttons"
          sx={{ display: "flex", gap, py: 5, justifyContent: "center" }}
        >
          <Button
            type="button"
            color="secondary"
            onClick={onCancel}
            variant="contained"
            sx={{ width: "48%" }}
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ width: "48%" }}
          >
            {t("accept")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ProductEditor;
