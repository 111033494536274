import { useEffect, useMemo, useState } from "react";
import { Alert, CircularProgress, Container, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import apiAgent from "../../apiAgent";
import { useTheme } from "@emotion/react";
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from "@mui/x-data-grid";

const Row = React.memo((props) => {
  const { row, columns } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment key={row.id}>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns?.map((column, i) => (
          <React.Fragment key={i}>
            <TableCell>
              {column?.valueGetter
                ? column.valueGetter({ row })
                : row[column.field]}
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>{JSON.stringify(row.context)}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});
const Logs = () => {
  const { t } = useTranslation();

  const [logs, setLogs] = useState({ data: [] });
  const [loading, setLoading] = useState(true);
  const [queryParameters, setQueryParameters] = useState({ page: 1 });
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        field: "description",
        headerName: t("description"),
        width: "30%",
        valueGetter: ({ row }) => t("logs-" + row?.type, { ...row?.context }),
      },
      {
        field: "userId",
        headerName: t("user"),
        width: "20%",
        valueGetter: ({ row }) =>
          [row.userId, row.email, row.username].join("-"),
      },
      {
        field: "created_at",
        headerName: t("date"),
        width: "10%",
        valueGetter: ({ row }) =>
          t("dateFormat", {
            val: new Date(row.created_at),
            interpolation: { escapeValue: false },
            timeStyle: "short",
            dateStyle: "short",
          }),
      },
      {
        field: "executionTimeSecs",
        headerName: t("executionTimeSecs"),
        width: "15%",
      },
    ],
    [t]
  );

  const handleSort = React.useCallback((column) => {
    setQueryParameters((old) => {
      let sortDirection =
        old.sortBy === column.field
          ? old.sortDirection === "ASC"
            ? "DESC"
            : undefined
          : "ASC";
      return {
        ...old,
        sortBy: sortDirection ? column.field : undefined,
        sortDirection,
      };
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    let { orderCycle, ...queryParametersApi } = queryParameters;
    apiAgent.logs
      .get(queryParametersApi)
      .then(setLogs)
      .then(() => setLoading(false));
  }, [queryParameters]);
  useEffect(() => {
    apiAgent.logs.getTypes();
  }, []);
  const handlePageChange = React.useCallback((e, page) => {
    setQueryParameters((old) => ({ ...old, page }));
  }, []);
  return (
    <Container maxWidth="lg">
      <Typography variant="h4">{t("logs")}</Typography>
      <TableContainer
        component={Paper}
        sx={{ my: 3, filter: "brightness(0.4) invert(1)" }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell width="10%" />
              {columns.map((column) => (
                <TableCell
                  onClick={() => handleSort(column)}
                  width={column.width || "10%"}
                  sx={{
                    userSelect: "none",
                    "&:hover": { color: theme.palette.primary.main },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>{column.headerName || column.field}</Typography>
                    <Typography sx={{ pl: 3 }}>
                      {queryParameters?.sortBy &&
                        queryParameters.sortBy === column.field &&
                        (queryParameters.sortDirection === "ASC" ? (
                          <GridArrowDownwardIcon />
                        ) : (
                          <GridArrowUpwardIcon />
                        ))}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              logs?.data?.map((row) => (
                <Row key={row.name} row={row} columns={columns} />
              ))}
            {logs?.data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={100}>
                  <Alert variant="info">{t("emptyResults")}</Alert>
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={100}>
                  <Box
                    sx={{
                      minHeight: "10em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {logs && (
          <Pagination
            page={logs?.current_page || null}
            count={logs?.last_page}
            disabled={loading}
            onChange={handlePageChange}
          />
        )}
      </Box>
    </Container>
  );
};
export default Logs;
