import React from "react";

import { confirmable } from "react-confirm";

import { useTranslation } from "react-i18next";
import { createConfirmation } from "react-confirm";

import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";

const ConfirmDialog = ({ show, proceed, options = {} }) => {
  const { t } = useTranslation();
  return (
    <Dialog onClose={() => proceed(false)} open={show}>
      <DialogTitle sx={{ px: "4em", py: "2em" }}>
        {options?.message || t("confirmationQuestion")}
      </DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={() => proceed(false)}>
          {t("cancel")}
        </Button>
        <Button color="secondary" onClick={() => proceed(true)}>
          {t("accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmableDialog = confirmable(ConfirmDialog);

export const confirm = createConfirmation(ConfirmableDialog);

export function confirmWrapper(confirmation, options = {}) {
  return confirm({ confirmation, options });
}
