import axios from "axios";
//import * as clientStorage from "./ClientStorageHelper";

const baseURL = process.env.REACT_APP_API_BASE_URL;

axios.defaults.withCredentials = true;
const axiosInstance = axios.create({ baseURL, withCredentials: true });
let handleForbidden;
const setHandleForbidden = (callback) => (handleForbidden = callback);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleForbidden(error?.response);
    }
    return Promise.reject(error);
  }
);
const downloadFile = (response, name) => {
  var blob = new Blob([response.data], { type: response, endings: "native" });
  var link = window.document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = name || "download";
  window.document.body.appendChild(link);
  link.click();
  window.document.body.removeChild(link);
};
const apiClient = {
  getUrl: (path, alternativeBase) => (alternativeBase || baseURL) + path,
  getFile: (url, params) =>
    axiosInstance.get(url, { params, responseType: "blob" }),
  get: (url, params) =>
    axiosInstance.get(url, { params }).then((res) => {
      if (!res.statusText === "OK") {
        throw res.data;
      }
      return res.data;
    }),
  post: (url, data) =>
    axiosInstance.post(url, data).then((res) => {
      if (!res.statusText === "OK") {
        throw res.body;
      }
      return res.data;
    }),
  put: (url, data) =>
    axiosInstance.put(url, data).then((res) => {
      if (!res.statusText === "OK") {
        throw res.data;
      }
      return res.data;
    }),
  delete: (url) =>
    axiosInstance.delete(url).then((res) => {
      if (!res.statusText === "OK") {
        throw res.data;
      }
      return res.data;
    }),
  patch: (url, data) =>
    axiosInstance.patch(url, data).then((res) => {
      if (!res.statusText === "OK") {
        throw res.data;
      }
      return res.data;
    }),
  postFormData: (url, formData) =>
    axiosInstance.post(url, formData, {
      headers: {
        accept: "application/json",
        "Content-Type": `multipart/form-data`,
      },
    }),
  putFormData: (url, formData) =>
    axiosInstance.put(url, formData, {
      headers: {
        accept: "application/json",
        "Content-Type": `multipart/form-data`,
      },
    }),
};

const apiAgent = {
  products: {
    categories: {
      getAll: () => apiClient.get("/v1/products/categories/all"),
      create: (category) => apiClient.post("/v1/products/categories", category),
      update: (category) =>
        apiClient.put(
          `/v1/products/categories/${category.categoryId}`,
          category
        ),
      delete: (categoryId) =>
        apiClient.delete(`/v1/products/categories/${categoryId}`),
    },
    prices: {
      getByProduct: (product, params) =>
        apiClient.get(`/v1/products/prices/${product.productId}`, params),
      add: (payload) => apiClient.post(`/v1/products/prices`, payload),
      massive: {
        logistics: {
          get: () => apiClient.getFile(`/v1/products/massive/logistics`),
          post: (file) => {
            let formData = new FormData();
            formData.append("excel", file, file.name);
            return apiClient.postFormData(
              "/v1/products/massive/logistics",
              formData
            );
          },
        },
      },
    },
    create: (product) => apiClient.postFormData("/v1/products", product),
    getAll: () => apiClient.get("/v1/products/all"),
    delete: (product) => apiClient.delete(`/v1/products/${product.productId}`),
    update: (product) => apiClient.put(`/v1/products`, product),
    updateImg: (product) =>
      apiClient.postFormData(`/v1/products/updateImg`, product),
  },
  users: {
    getAll: () => apiClient.get(`/v1/users`, { bypassPagination: true }),
    create: (user) => apiClient.post(`/v1/users`, user),
    delete: (user) => apiClient.delete(`/v1/users/${user.userId}`),
    update: (user) => apiClient.put(`/v1/users/${user.userId}`, user),
  },
  request: {
    create: (request) => apiClient.post(`/v1/request`, request),
    delete: (request) =>
      apiClient.delete(`/v1/request/${request.requestHeaderId}`),
    get: (params) => apiClient.get(`/v1/request`, params),
    update: (request) =>
      apiClient.patch(`/v1/request/${request.requestHeaderId}`, request),
    updateState: (request, params) =>
      apiClient.patch(`/v1/request/state/${request.requestHeaderId}`, params),
    getDetailRows: (request) =>
      apiClient.get(`/v1/request/detailRows/${request.requestHeaderId}`),
    putQtyDetailRow: (row, params) =>
      apiClient.patch(`/v1/request/detailRow/${row}`, params),
    patchDeliveryDate: (request, params) =>
      apiClient.patch(
        `/v1/request/${request.requestHeaderId}/deliveryDate`,
        params
      ),
    getStateHistory: (request) =>
      apiClient.get(`/v1/request/stateHistory/${request.requestHeaderId}`),
    getDeliveryNote: (request, a) =>
      apiClient.getUrl(
        `/v1/request/${request.requestHeaderId}/deliveryNote`,
        a
      ),
    getWorkOrder: (request, a) =>
      apiClient.getUrl(`/v1/request/${request.requestHeaderId}/workOrder`, a),
  },
  franchises: {
    get: (params) => apiClient.get(`/v1/franchise`, params),
    create: (franchise) => apiClient.post(`/v1/franchise`, franchise),
    update: (franchise) =>
      apiClient.patch(`/v1/franchise/${franchise.franchiseId}`, franchise),
    delete: (franchiseId) => apiClient.delete(`/v1/franchise/${franchiseId}`),
  },
  location: {
    countries: {
      all: () => apiClient.get(`/v1/location/countries`),
    },
    states: {
      getByCountry: (country) =>
        apiClient.get(`/v1/location/country/${country.countryId}/states`),
    },
    settings: {
      setDeliveryNoteHeaders: (params) =>
        apiClient.post(`/v1/location/settings/deliveryNoteHeaders`, params),
      getDeliveryNoteHeaders: (params) =>
        apiClient.get(`/v1/location/settings/deliveryNoteHeaders`, params),
    },
  },
  logs: {
    get: (params) => apiClient.get(`/v1/logs`, params),
    getTypes: () => apiClient.get(`/v1/logs/types`),
  },
  session: {
    logout: () => apiClient.post(`/v1/auth/logout`),
    login: (credentials) => apiClient.post("/v1/auth/login", credentials),
    csrfCookie: () => axiosInstance.get("/sanctum/csrf-cookie"),
    me: () => apiClient.get(`/v1/auth/me`),
    setHandleForbidden,
    setLocale: (params) => apiClient.post(`/v1/locale`, params),
  },

  downloadFile,
};

export default apiAgent;
