import React, { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../AppContextProvider";

const GuardedRoute = ({
  children,
  roles = null,
  permissions,
  guard,
  fallback,
  ...rest
}) => {
  const { renderIfRole, renderIfPermission, state } = useContext(AppContext);

  const allowed = useMemo(() => {
    let value = true;
    if (!state?.session?.user) {
      return false;
    }
    if (renderIfRole("admin")) return true;
    if (roles) {
      value = renderIfRole(...roles);
    }
    if (permissions) {
      value = value && renderIfPermission(...permissions);
    }
    return value;
  }, [roles, permissions, renderIfPermission, renderIfRole, state?.session]);
  return allowed ? children : <Navigate to="/loggingOut" />;
};

export default GuardedRoute;
